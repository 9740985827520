import Vue from 'vue'
import Vuex from 'vuex'
import {getSliders} from '@/helpers/api/sliders'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		sliders: [],
		totalSliders: 0,
		loadingSliders: true
	},
	mutations: {
		setSliders(state, sliders){
			state.sliders = sliders
		},
		setTotalSliders(state, totalSliders){
			state.totalSliders = totalSliders
		},
		setLoadingSliders(state, loading){
			state.loadingSliders = loading
		},
	},
	actions: {
		async getSliders(context, query){
			context.commit('setLoadingSliders', true)
			const sliders = await getSliders(query)
			if(sliders.code == 200){
				context.commit('setSliders', sliders.sliders)
				context.commit('setTotalSliders', sliders.total)
			}
			context.commit('setLoadingSliders', false)
		}
	}
})

export default store