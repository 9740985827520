<template>
	<div>
		<h2 class="mb-2">{{post.title}}</h2>
		<v-img
			lazy-src="https://picsum.photos/id/11/10/6"
			:aspect-ratio="80/45"
			:src="post.img"
			class="mb-2"
		></v-img>
		<div v-if="fullHtml" v-html="fullHtml" style="width: 100%">
		</div>
	</div>
</template>

<script>
export default {
	name: 'PostComponent',
	props: ['post'],
	watch: {
		post: {
			handler(newData) {
				console.log(newData)
				// if(newData){
				// 	this.fullHtml = '<html><header><style>' + newData.content.css + '</style></header><body>' + newData.content.html + '</body><html/>'
				// }
			},
			deep: true
		}
	},
	data: () => ({
		fullHtml: null
	}),
	mounted(){
		console.log(this.post)
		if(this.post.content){
			this.fullHtml = '<html><header><style>' + this.post.content.css + '</style></header><body>' + this.post.content.html + '</body><html/>'
		}
	},
	methods: {
	}
};
</script>