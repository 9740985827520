import menuStore from './MenuStore.js'
import userStore from './UserStore.js'
import postsStore from './PostsStore.js'
import slidersStore from './SlidersStore.js'
import navigationStore from './NavigationStore.js'
import footerStore from './FooterStore.js'
import featuredStore from './FeaturedStore'
import headerStore from './HeaderStore'
import logoStore from './LogoStore'
import pagesStore from './PagesStore'
import layoutStore from './LayoutStore'
import multimediaStore from './MultimediaStore'

const store = {
	menuStore,
	userStore,
	postsStore,
	slidersStore,
	navigationStore,
	footerStore,
	featuredStore,
	logoStore,
	headerStore,
	pagesStore,
	layoutStore,
	multimediaStore
}

export default store