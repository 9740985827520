var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-start",attrs:{"pa-5":"","fluid":"","fill-height":""}},[(_vm.info)?_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-img',{attrs:{"max-height":"50","max-width":"150","src":require('@/assets/imgs/logoclick2callmini.png')}}),_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","color":"#4D3489","dark":"","small":"","href":"https://panelclick2call.web.app","target":"_blank"}},[_c('p',{staticClass:"mb-0 ml-2"},[_vm._v("Panel Click2Call")])])],1),_c('v-divider',{staticClass:"mt-2 mb-4"})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"pa-5",attrs:{"color":"#f2f2f2","shaped":""}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#4D3489"}},[_vm._v("mdi-monitor-dashboard")]),_c('h4',{staticStyle:{"color":"#4D3489"}},[_vm._v("Videollamadas Click2Call")])],1),_c('div',{staticClass:"rounded-lg py-1 px-2",staticStyle:{"background-color":"#4D3489"}},[_c('p',{staticClass:"white--text mb-0 caption text-right"},[_vm._v("Total: 22")]),_c('p',{staticClass:"white--text mb-0 caption text-right"},[_vm._v("Atendidas: 18")])])]),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('BarChart',{staticStyle:{"max-height":"200px"},attrs:{"chartdata":{
						labels: ['11', '12', '13', '14','15', '16', '17'],
						datasets: [
							{
								label: 'Recibidas',
								backgroundColor: '#4D3489',
								data: _vm.info.videollamadas
							},
							{
								label: 'Atendidas',
								backgroundColor: '#075c07',
								data: _vm.info.videollamadasA
							}
						]
					},"options":{
						responsive: true,
						maintainAspectRatio: false,
						legend: {
							display: false
						},
						tooltips: {
							callbacks: {
								label: function(tooltipItem) {
									return tooltipItem.yLabel;
								}
							}
						},
						scales: {
							xAxes: [{
								ticks: {
									fontColor: '#4D3489'
								},
								gridLines: {
									color: '#9783c5'
									}
							}],
							yAxes: [{
								ticks: {
									fontColor: '#4D3489',
									beginAtZero: true
								},
								gridLines: {
									color: '#9783c5'
								}
							}]
						}
					}}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"pa-5",attrs:{"color":"#f2f2f2","shaped":""}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#4D3489"}},[_vm._v("mdi-email-outline")]),_c('h4',{staticStyle:{"color":"#4D3489"}},[_vm._v("Videomails Click2Call")])],1),_c('div',{staticClass:"rounded-lg py-1 px-2",staticStyle:{"background-color":"#4D3489"}},[_c('p',{staticClass:"white--text mb-0 caption text-right"},[_vm._v("Total: 22")]),_c('p',{staticClass:"white--text mb-0 caption text-right"},[_vm._v("Atendidos: 18")])])]),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('BarChart',{staticStyle:{"max-height":"200px"},attrs:{"chartdata":{
						labels: ['11', '12', '13', '14','15', '16', '17'],
						datasets: [
							{
								label: 'Recibidas',
								backgroundColor: '#4D3489',
								data: _vm.info.videollamadas
							},
							{
								label: 'Atendidas',
								backgroundColor: '#075c07',
								data: _vm.info.videollamadasA
							}
						]
					},"options":{
						responsive: true,
						maintainAspectRatio: false,
						legend: {
							display: false
						},
						tooltips: {
							callbacks: {
								label: function(tooltipItem) {
									return tooltipItem.yLabel;
								}
							}
						},
						scales: {
							xAxes: [{
								ticks: {
									fontColor: '#4D3489'
								},
								gridLines: {
									color: '#9783c5'
								}
							}],
							yAxes: [{
								ticks: {
									fontColor: '#4D3489',
									beginAtZero: true
								},
								gridLines: {
									color: '#9783c5'
								}
							}]
						}
					}}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"pa-5",attrs:{"color":"#f2f2f2","shaped":""}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#4D3489"}},[_vm._v("mdi-phone")]),_c('h4',{staticStyle:{"color":"#4D3489"}},[_vm._v("Llamadas Telefónicas Click2Call")])],1),_c('div',{staticClass:"rounded-lg py-1 px-2",staticStyle:{"background-color":"#4D3489"}},[_c('p',{staticClass:"white--text mb-0 caption text-right"},[_vm._v("Total: 22")]),_c('p',{staticClass:"white--text mb-0 caption text-right"},[_vm._v("Atendidas: 18")])])]),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('BarChart',{staticStyle:{"max-height":"200px"},attrs:{"chartdata":{
						labels: ['11', '12', '13', '14','15', '16', '17'],
						datasets: [
							{
								label: 'Recibidas',
								backgroundColor: '#4D3489',
								data: _vm.info.videollamadas
							},
							{
								label: 'Atendidas',
								backgroundColor: '#075c07',
								data: _vm.info.videollamadasA
							}
						]
					},"options":{
						responsive: true,
						maintainAspectRatio: false,
						legend: {
							display: false
						},
						tooltips: {
							callbacks: {
								label: function(tooltipItem) {
									return tooltipItem.yLabel;
								}
							}
						},
						scales: {
							xAxes: [{
								ticks: {
									fontColor: '#4D3489'
								},
								gridLines: {
									color: '#9783c5'
								}
							}],
							yAxes: [{
								ticks: {
									fontColor: '#4D3489',
									beginAtZero: true
								},
								gridLines: {
									color: '#9783c5'
								}
							}]
						}
					}}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }