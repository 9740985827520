<template>
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <div class="d-flex align-center justify-space-between" style="width: 100%">
      <div class="d-flex align-center">
        <h2>Pie de página</h2>
        <v-btn
          depressed
          color="#4D3489"
          dark
          small
          class="ml-4"
          @click="$router.push('/table-footer')"
        >
          <v-icon small>mdi-arrow-left</v-icon>
          <p class="mb-0 ml-2">Volver</p>
        </v-btn>
      </div>
      <div>
        <v-btn
          depressed
          color="#4D3489"
          dark
          small
          class="mr-4"
          @click="showPreview = true"
        >
          <v-icon small>mdi-eye</v-icon>
          <p class="mb-0 ml-2">Previsualizar</p>
        </v-btn>
        <v-btn depressed color="#4D3489" dark small @click="setPost()">
          <p class="mb-0">
            {{ this.$route.params.id ? "Actualizar" : "Confirmar" }}
          </p>
        </v-btn>
      </div>
    </div>
    <v-divider class="mb-5" style="width: 100%"></v-divider>
    <div style="width: 100%">
      <v-overlay :value="overlay">
        <v-progress-circular
          :size="70"
          :width="4"
          color="#4D3489"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <div class="mt-10" id="gjs">
        {{ footer.html }}
      </div>
    </div>
    <v-dialog v-model="showPreview" width="700">
      <v-card>
        <v-btn icon color="grey" @click="() => (showPreview = false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="pb-5 px-4">
          <ActiveFooter v-if="footer" :footer="footer" />
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
// import { getFooterId } from "@/helpers/api/footer";
import { uploadImage } from "@/helpers/api/files";
import grapesjsWeb from "grapesjs-preset-webpage";
import ActiveFooter from "./ActiveFooter";
import request from "@/helpers/request";

export default {
  name: "Footer",
  components: { ActiveFooter },
  data: () => ({
    overlay: true,
    valid: false,
    showPreview: false,
    footer: {
      html: "",
      css: "",
    },
    alert: {
      show: false,
      message: "",
      type: "success",
    },
    loadingUploadImage: false,
    newImgBase64: null,
  }),
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getFooter(id);
    } else {
      this.overlay = false;
    }
    this.setGrapesJsEditor();
  },
  methods: {
    async getFooter(id) {
      this.overlay = true;
      const resp = await request.get(`footer/${id}`);
      if (resp.success) {
        this.footer = resp.data;
        setTimeout(() => {
          this.editor.setComponents(this.footer.html);
          this.editor.setStyle(this.footer.css);
        }, 10);
      }
      this.overlay = false;
    },
    setPost() {
      this.footer.html = this.editor.getHtml();
      this.footer.css = this.editor.getCss();

      if (!this.footer.html)
        return Swal.fire({
          icon: "error",
          color: "#5b4492",
          title: "Error",
          text: "Debe agregar contenido a su pie de página",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            title: "font-family: Roboto, seri",
          },
        });

      if (this.$route.params.id) {
        this.$store.footerStore.dispatch("updateColumn", {
          footer: this.footer,
          overlay: this.overlay,
          id: this.$route.params.id,
        });
      } else {
        this.$store.footerStore.dispatch("createColumn", this.footer, {
          overlay: this.overlay,
        });
      }
    },
    setGrapesJsEditor() {
      const self = this;
     
      this.editor = grapesjs.init({
        container: "#gjs",
        fromElement: true,
        height: "800px",
        // width: "100%",
        storageManager: false,
        forceClass: true,
        plugins: [grapesjsWeb],
        panels: { defaults: [] },
        pluginsOpts: {
          "gjs-blocks-basic": {
            stylePrefix: "", // no gjs- prefix
            flexGrid: 1, // use flexbox instead of tables
          },
        },
        protectedCss: `.ddm-flex-cols {
          display: flex;
          flex-direction: row;
          margin: -16px;
          padding: 16px 0px;
          flex-wrap: wrap; }
          .ddm-flex-cols .ddm-flex-cols-element {
          flex-grow: 2;
          margin: 16px;
          flex-shrink: 0;
          flex-basis: 0; }`,

        assetManager: {
          uploadFile: async function (e) {
            const file = e.dataTransfer
              ? e.dataTransfer.files[0]
              : e.target.files[0];
            const upload = await uploadImage(file, 'footerFiles');
            console.log(upload);
            if (upload.code == 200) {
              const url = upload.image_url;
              self.editor.AssetManager.add(url);
            }
          },
        },
      });

       this.editor.on("component:update", model => {
        console.log("New content selected", model.get("content"));
          // do your stuff...
       });

       const prefix = "myprefix-";

      this.editor.on("selector:add", (selector) => {
        const name = selector.get("name");

        if (
          selector.get("type") === this.editor.SelectorManager.Selector.TYPE_CLASS &&
          name.indexOf(prefix) !== 0
        ) {
          selector.set("name", prefix + name);
        }
      });
			this.editor.on('component:add', function(model) {
				if(model.attributes.tagName === 'img'){
					model.set('style', { 'max-width': '100%' })
				}
			})

      //   this.getFilesList();
    },
  },
  
};
</script>

<style scoped>
.post-preview-container {
  background-color: white;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 10px;
  padding: 10px;
}
.content-preview {
  width: 100%;
}
/deep/ .content-preview img {
  width: 100%;
}
.swal2-title {
  font-family: "fontname" !important;
}
</style>