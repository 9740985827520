<template>
	<v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
		<div class="d-flex align-center">
			<h2>
        {{this.$route.params.item ? 'Actualizar item de navegación' : 'Nuevo item de navegación'  }}
        
        </h2>
			<v-btn
				depressed
				color="#4D3489"
				dark
				small
				class="ml-4"
				@click="$router.push('Navigations')" 
			>
				<v-icon small>mdi-arrow-left</v-icon>
				<p class="mb-0 ml-2">Volver</p>
			</v-btn>
		</div>
		<v-divider class="mb-5" style="width: 100%;"></v-divider>
		<div style="width: 100%;">
			<v-overlay :value="overlay">
				<v-progress-circular
					:size="70"
					:width="4"
					color="#4D3489"
					indeterminate
				></v-progress-circular>
			</v-overlay>



  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >

   <v-row>
    <v-col
          cols="12"
          sm="6"
        >
    <v-select
      v-model="formItem.position"
      :items="formItem.items"
      :rules="[v => !!v || 'La posición es requerida']"
      label="Posición"
      required
    ></v-select>

    </v-col>
     <v-col
          cols="12"
          sm="6"
        >
		<div class="d-flex justify-center align-center">
			<v-menu bottom nudge-bottom offset-y :close-on-content-click="false">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="#4D3489"
						class="mr-3"
						dark
						small
						v-bind="attrs"
						v-on="on"
					>
						Seleccionar Icono
					</v-btn>
				</template>

				<icon-picker v-model="formItem.icon" />
			</v-menu>
			<v-text-field
				v-model="formItem.icon"
				:counter="10"
				:rules="formItem.iconRules"
				label="Icono"
				required
			>
				<template v-slot:prepend-inner>
					<v-icon >{{formItem.icon}}</v-icon>
				</template>
			</v-text-field>
		</div>
    <!-- <v-select
      v-model="formItem.icon"
      :items="formItem.icons"
      :rules="[v => !!v || 'La posición es requerida']"
      label="Iconos"
      required
    ></v-select> -->

    </v-col>

   </v-row>

    <v-text-field
      v-model="formItem.name"
      :counter="10"
      :rules="formItem.nameRules"
      label="Nombre"
      required
    ></v-text-field>

     <v-text-field
      v-model="formItem.path"
      :counter="10"
      :rules="formItem.pathRules"
      label="Path"
      required
    ></v-text-field>

   

    <!-- <v-checkbox
      v-model="checkbox"
      :rules="[v => !!v || 'You must agree to continue!']"
      label="Do you agree?"
      required
    ></v-checkbox> -->

   
  
    <v-btn
		depressed
    class="mt-10"
		color="#4D3489"
		dark
		block
		@click="saveItemNavigation()"
	>
		<p class="mb-0 ml-2 body-2">Confirmar</p>
	</v-btn>

  

  </v-form>




	</div>
	</v-container>
</template>

<script>

import {mapActions} from 'vuex'
import IconPicker from "vuetify-icon-picker"

  export default {
		components: {
			IconPicker
		},
    data: () => ({
      overlay: true,
      valid: true,
      formItem: {
          name: '',
          nameRules: [
            v => !!v || 'El nombre es requerido',
            v => (v && v.length <= 50) || 'El nombre debe tener menos de 50 caracteres',
          ],
          path: '',
          pathRules: [
            v => !!v || 'El path es requerido',
            v => (v && v.length <= 50) || 'El path debe tener menos de 50 caracteres',
          ],
          position: null,
          items: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
          ],
          icon: '',
          iconRules: [
            v => !!v || 'El nombre del icono es requerido',
            v => (v && v.length <= 100) || 'El icono debe tener menos de 100 caracteres',
          ],
          //   icons: [
          //   'mdi-account-lock-open',
          //   'test 2',
          //   'test 3',
          //   'test 4',
          // ],
        //   checkbox: false,

      }
    }),
    created(){
      const item = this.$route.params.item
        if(item){
          this.formItem = {
          name: item.name,
          nameRules: [
            v => !!v || 'El nombre es requerido',
            v => (v && v.length <= 50) || 'El nombre debe tener menos de 50 caracteres',
          ],
          path: item.path,
          pathRules: [
            v => !!v || 'El path es requerido',
            v => (v && v.length <= 50) || 'El path debe tener menos de 50 caracteres',
          ],
          position: item.position,
          items: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
          ],
          icon: item.icon,
          iconRules: [
            v => !!v || 'El nombre del icono es requerido',
            v => (v && v.length <= 100) || 'El icono debe tener menos de 100 caracteres',
          ],
          // icon: item.icon,
          //   icons: [
          //   'mdi-account-lock-open',
          //   'test 2',
          //   'test 3',
          //   'test 4',
          // ],
        //   checkbox: false,

      }
        }
    },
    mounted() {
      this.overlay = false
      console.log(this.$route.params)
      console.log(this.$route.params.id)
    },
    methods:  {
    ...mapActions('navigationStore',['createItemNavigation']),
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      async saveItemNavigation () {
          this.$refs.form.validate()
          if(!this.$refs.form.validate()) return
          const {name,path, position, icon } = this.formItem
          
          const level = this.$route.params.level || this.$route.params.item.level
          const type = this.$route.params.type
          const idP = this.$route.params.id

          this.overlay = true
					if(this.$route.params.item) {
						const { id } = this.$route.params.item
						this.$store.navigationStore.dispatch('updateItemNavigation', {id, name,path, position, level, icon, reset:this.reset, overlay: this.overlay})
					}else {
						if(!this.$route.params.id) {
						this.$store.navigationStore.dispatch('createItemNavigation', 
						{
						name,
						path, 
						position, 
						icon, 
						reset:this.reset, 
						overlay: this.overlay, 
						level,
						sidebar_item_type_id:type
						})
						}else {
							this.$store.navigationStore.dispatch('createItemNavigation', 
							{
							sidebar_item_id: idP, 
							name,path, 
							position, 
							icon, 
							reset:this.reset, 
							overlay: 
							this.overlay, 
							level,
							sidebar_item_type_id:type
							})
						}

					}
      
        
          
          
      }
    },
  }
</script>