<template>
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <div class="d-flex align-center justify-space-between" style="width: 100%">
      <div class="d-flex align-center">
        <h2>Post</h2>
        <v-btn
          depressed
          color="#4D3489"
          dark
          small
          class="ml-4"
          @click="$router.push('Posts')"
        >
          <v-icon small>mdi-arrow-left</v-icon>
          <p class="mb-0 ml-2">Volver</p>
        </v-btn>
      </div>
      <div>
        <v-btn
          depressed
          color="#4D3489"
          dark
          small
          class="mr-4"
          @click="testGrapess()"
        >
          <v-icon small>mdi-eye</v-icon>
          <p class="mb-0 ml-2">Previsualizar</p>
        </v-btn>
        <v-btn depressed color="#4D3489" dark small @click="setPost()">
          <p class="mb-0">Confirmar</p>
        </v-btn>
      </div>
    </div>
    <v-divider class="mb-5" style="width: 100%"></v-divider>
    <div style="width: 100%">
      <v-overlay :value="overlay">
        <v-progress-circular
          :size="70"
          :width="4"
          color="#4D3489"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col cols="12" md="12">
          <v-form v-model="valid" style="width: 100%">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="post.title"
                  :counter="255"
                  label="Título"
                  required
                  class="my-0"
                  @change="changeTitle"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="post.url"
                  :counter="255"
                  label="Url"
                  required
                  class="my-0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-file-input
                  accept="image/*"
                  label="Imagen Portada"
                  @change="imgSelected"
                  hint="Ten en cuenta que en la web la relación de aspecto será de 16:9. Guíate por el ejemplo al costado."
                  persistent-hint
                ></v-file-input>
              </v-col>
              <v-col cols="3">
                <v-menu
                  ref="menu"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :return-value.sync="post.start_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="post.start_at"
                      label="Fecha inicio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="post.start_at"
                    no-title
                    scrollable
                    locale="es-cl"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDate = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(post.start_at)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <v-menu
                  ref="menuend"
                  v-model="menuDateEnd"
                  :close-on-content-click="false"
                  :return-value.sync="post.start_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="post.end_at"
                      label="Fecha fin"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="post.end_at"
                    no-title
                    scrollable
                    locale="es-cl"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDateEnd = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuend.save(post.end_at)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="post.important"
                  label="Importante"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3 class="mb-3">Contenido</h3>
                <div id="gjs">
                  {{ post.html }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn depressed color="#4D3489" dark block @click="setPost()">
                  <p class="mb-0 ml-2 body-2">Confirmar</p>
                </v-btn>
                <v-alert v-if="alert.show" dense text :type="alert.type">
                  {{ alert.message }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showPreview" width="700">
      <v-card>
        <v-btn icon color="grey" @click="() => (showPreview = false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="pb-5 px-4">
          <PostComponent v-if="post" :post="post" />
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getPost, updatePost, newPost } from "@/helpers/api/posts";
import { uploadImage, getFiles } from "@/helpers/api/files";
import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
import grapesjsWeb from "grapesjs-preset-webpage";
import PostComponent from "./PostComponent";

export default {
  name: "Post",
  components: { PostComponent },
  data: () => ({
    overlay: true,
    valid: false,
    menuDate: false,
    menuDateEnd: false,
    showPreview: false,
    post: {
      title: "",
      html: "",
      css: "",
      url: "",
      start_at: "",
      end_at: "",
      img: "",
      newPostImg: null,
			important: false
    },
    alert: {
      show: false,
      message: "",
      type: "success",
    },
    loadingUploadImage: false,
    newImgBase64: null,
		isNewPost: false
  }),
  mounted() {
    const post_id = this.$route.query.post_id || this.$route.params.post_id;
    if (post_id) {
      this.getPost({ id: post_id });
    } else if (this.$route.params.newPost) {
      this.post = {
        title: "",
        html: "",
        start_at: this.$options.filters.toDate(new Date()),
        end_at: "",
        img: "",
        url: "",
        newPostImg: null,
				important: false
      }
			this.isNewPost = true
      this.overlay = false
    } else {
      this.$router.push({ name: "Posts" });
    }
    this.setGrapesJsEditor();
  },
  methods: {
    async getPost(query) {
      this.overlay = true;
      const post = await getPost(query);
      if (post.code == 200) {
        this.post = post.post;
        console.log(this.post);
        setTimeout(() => {
          this.editor.setComponents(this.post.content.html);
          this.editor.setStyle(this.post.content.css);
        }, 10);
      }
      this.overlay = false;
    },
    imgSelected(img) {
      this.post.newPostImg = img;
      this.getBase64(this.post.newPostImg);
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.newImgBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    testGrapess() {
      this.showPreview = true;
      this.post.content.html = this.editor.getHtml();
      this.post.content.css = this.editor.getCss();
    },
    setPost() {
      this.post.html = this.editor.getHtml();
      this.post.css = this.editor.getCss();
      if (this.isNewPost) {
        this.createPost();
      } else {
        this.updatePost();
      }
    },
    async createPost() {
			let data = this.post
			data.important = this.post.important ? 1 : 0
      const post = await newPost(data)
      if (post.code == 200) {
        this.alert = {
          show: true,
          message: "Post creado correctamente",
          type: "success",
        };
        this.post = post.post
				this.isNewPost = false
      } else {
        this.alert = {
          show: true,
          message:
            "Hubo un error al crear el post, por favor intenta nuevamente",
          type: "warning",
        };
      }
      setTimeout(() => {
        this.alert = {
          show: false,
          message: "",
          type: "success",
        };
      }, 4000);
    },
    async updatePost() {
			let data = this.post
			data.important = this.post.important ? 1 : 0
      const update = await updatePost(data);
      console.log(update);
      if (update.code == 200) {
        this.alert = {
          show: true,
          message: "Post actualizado correctamente",
          type: "success",
        };
        this.post = update.post;
      } else {
        this.alert = {
          show: true,
          message: "Hubo un error al actualizar, por favor intenta nuevamente",
          type: "warning",
        };
      }
      setTimeout(() => {
        this.alert = {
          show: false,
          message: "",
          type: "success",
        };
      }, 4000);
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.loadingUploadImage = true;
      const upload = await uploadImage(file);
      if (upload.code == 200) {
        const url = upload.image_url;
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      }
      this.loadingUploadImage = false;
    },
    changeTitle(text) {
      console.log("t: ", text);
      if (this.post.url == "") {
        this.post.url = this.toSeoUrl(text);
      }
    },
    toSeoUrl(url) {
      return url
        .toString() // Convert to string
        .replace(" ", "-") // Change space to -
        .normalize("NFD") // Change diacritics
        .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
        .replace(/\s+/g, "-") // Change whitespace to dashes
        .toLowerCase() // Change to lowercase
        .replace(/&/g, "-and-") // Replace ampersand
        .replace(/[^a-z0-9-]/g, "") // Remove anything that is not a letter, number or dash
        .replace(/-+/g, "-") // Remove duplicate dashes
        .replace(/^-*/, "") // Remove starting dashes
        .replace(/-*$/, ""); // Remove trailing dashes
    },
    setGrapesJsEditor() {
      const self = this;
      this.editor = grapesjs.init({
        container: "#gjs",
        fromElement: true,
        height: "800px",
        width: "100%",
        storageManager: false,
        plugins: [grapesjsWeb],
        panels: { defaults: [] },
        protectedCss: "",
        assetManager: {
          uploadFile: async function (e) {
            console.log(e);
            const file = e.dataTransfer
              ? e.dataTransfer.files[0]
              : e.target.files[0];
            const upload = await uploadImage(file, 'postImages');
            if (upload.code == 200) {
              const url = upload.image_url;
              self.editor.AssetManager.add(url);
            }
          },
        },
      });
      const prefix = "myprefix-";

      this.editor.on("selector:add", (selector) => {
        const name = selector.get("name");
        if (
          selector.get("type") ===
            this.editor.SelectorManager.Selector.TYPE_CLASS &&
          name.indexOf(prefix) !== 0
        ) {
          selector.set("name", prefix + name);
        }
      })
			this.editor.on('component:add', function(model) {
				if(model.attributes.tagName === 'img'){
					model.set('style', { 'max-width': '100%' })
				}
			})
      this.getFilesList();
    },
    async getFilesList() {
      const files = await getFiles();
      if (files.code == 200) {
        this.editor.AssetManager.add(files.files);
      }
    },
  },
};
</script>

<style scoped>
.post-preview-container {
  background-color: white;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 10px;
  padding: 10px;
}
.content-preview {
  width: 100%;
}
/deep/ .content-preview img {
  width: 100%;
}
</style>