<template>
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <div class="d-flex align-center">
      <h2>Pie de página</h2>
      <v-btn
        depressed
        color="#4D3489"
        dark
        small
        class="ml-4"
        @click="$router.push({ name: 'Footer', params: { level: 1, type: 1 } })"
      >
        <v-icon small>mdi-plus</v-icon>
        <p class="mb-0 ml-2">Crear item</p>
      </v-btn>
    </div>
    <v-divider class="mb-5" style="width: 100%"></v-divider>
    <v-data-table
      :headers="headers"
      mobile-breakpoint
      :items="$store.footerStore.state.columns"
      :server-items-length="$store.footerStore.state.columns.length"
      :items-per-page="$store.footerStore.state.columns.length"
      :loading="$store.footerStore.state.loadingColumns || loadingDelete"
      loading-text="Cargando ..."
      no-data-text="No hay información"
      no-results-text="Sin resultados"
      class="elevation-1 post-table"
    >
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | toDateTime }}
      </template>
      <!-- <v-data-footer
       items-per-page-options="$store.navigationStore.state.items.length"
      items-per-page-text='jkdzhkjfh'
      page-text="sdkjhfkjsdfh"
    >
    </v-data-footer> -->

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              small
              color="orange"
              @click.stop="updateItem(item)"
            >
              <v-icon medium>mdi-update</v-icon>
            </v-btn>
          </template>
          <span>Actualizar</span>
        </v-tooltip>

 <v-tooltip top>
          <template v-slot:activator="{ on }">
        <v-btn
          class="ml-3"
          icon
          small
          v-on="on"
          color="red"
          @click.stop="deleteCol(item.id)"
        >
          <v-icon medium>mdi-delete</v-icon>
        </v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>

      </template>
    </v-data-table>

    <!-- <v-snackbar
      :value="alert.show"
      absolute
      top
      :color="alert.type"
      outlined
      right
    >
      {{alert.message}}
    </v-snackbar> -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loadingDelete: false,
      headers: [
        { text: "Creado por", value: "user.name" },
        { text: "Fecha de actualización", value: "created_at" },
        // { text: "Path", value: "path" },
        // { text: "Posición", value: "position" },
        // { text: 'Sub menu', value: 'sidebar_items' },
        { text: "Acciones", value: "actions" },
      ],
    };
  },

  methods: {
    getItems() {
      this.$store.footerStore.dispatch("getColumsFooter");
    },
    updateItem(item) {
      this.$router.push({ name: "Footer", params: { id: item.id } });
    },
    deleteCol(id) {
      this.$store.footerStore.dispatch("deleteColumn", id);
    },
  },
  created() {
    this.getItems();
  },
};
</script>

<style scoped>
.post-table {
  width: 100%;
}
.post-table >>> tbody tr :hover {
  cursor: pointer;
}
</style>
