<template>
  <v-navigation-drawer
    v-model="$store.menuStore.state.menuSidebarStatus"
    fixed
    :mini-variant="$store.menuStore.state.sidebarMini"
    :width="$store.menuStore.state.sidebarMini ? '50' : '220'"
  >
    <v-icon dark class="toggle-button pa-1" @click="toggleMenu"
      >mdi-menu-open</v-icon
    >
    <div style="width: 100%" class="fill-height">
      <v-row no-gutters justify="center">
        <v-col justify="center" class="text-center pa-4">
			<img v-if="$store.logoStore.state.logo" :src="$store.logoStore.state.logo.image" style="max-width: 110px; width: 100%;">
          <img v-else :src="require('../../assets/imgs/logo.png')" style="max-width: 110px; width: 100%;"/>
          <div v-if="$store.userStore.state.userInfo">
            <div v-if="!$store.menuStore.state.sidebarMini">
              <p class="mb-0 caption" style="color: #606060">Bienvenid@</p>
              <p class="mb-0" style="color: #606060">
                {{ $store.userStore.state.userInfo.name }}
              </p>
            </div>
          </div>
          <div v-else>
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <Menu />
      <div class="mt-5 px-2">
        <v-btn
          depressed
          color="#4D3489"
          dark
          block
          :small="$store.menuStore.state.sidebarMini"
          @click="logout()"
        >
          <v-icon>mdi-power</v-icon>
          <p
            class="mb-0 ml-2 body-2"
            v-show="!$store.menuStore.state.sidebarMini"
          >
            Salir
          </p>
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Menu from "./Menu/Index";
export default {
  name: "Sidebar",
  components: { Menu },
  data: () => ({}),
  mounted() {
    this.$store.logoStore.dispatch("getLogo");
  },
  methods: {
    toggleMenu() {
      if (this.$vuetify.breakpoint.mobile) {
        this.$store.menuStore.commit(
          "setMenuSidebarStatus",
          !this.$store.menuStore.state.menuSidebarStatus
        );
        return null;
      }
      this.$store.menuStore.commit("toggleSidebarMini");
    },
    logout() {
      this.$store.userStore.commit("logoutUser");
      this.$router.push({ name: "Login" });
    }
  },
};
</script>
<style>
.toggle-button {
  position: absolute !important;
  top: 0;
  right: 0;
  background: #4d3489;
}
.v-skeleton-loader__bone {
  margin-right: auto;
  margin-left: auto;
}
.sidebar-avatars-container {
  width: 100px;
  height: 100px;
  position: relative;
  margin: auto;
}
.user-avatar {
  width: 80px;
  height: 80px;
  border-radius: 80px;
}
.user-avatar.mini {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.user-avatar.icon {
  background-color: #f0f2f8;
}
.info-icon {
  background-color: #4d3489;
  border-radius: 25px;
  padding: 3px;
  width: 22px !important;
  height: 22px !important;
}
.project-avatar-sidebar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  position: absolute;
  bottom: 10px;
  right: 0;
}
</style>
