import {endpoints} from './endpoints'
import request from '../request'



export const newColumn = (newData) => {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createColumnFooter,
			method: 'post',
			data: newData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}


export const getColums = () => {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getColums,
			method: 'get',
			// params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}


export const getFooterId = (data) => {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.footerId,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}