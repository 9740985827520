import moment from 'moment'

const filters = {
	toThousandFilter(num) {
		return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, '.'))
	},
	toDateTime(date){
		return moment(date).format('DD-MM-YYYY HH:mm')
	},
	toDate(date, fromFormat = null, format = 'DD-MM-YYYY'){
		if(fromFormat){
			return moment(date, fromFormat).format(format)
		}
		return moment(date).format(format)
	},
	toHour(date){
		return moment(date).format('HH:mm')
	}
}

export default filters