<template>
	<v-container fluid pa-0 style="background-color: #F0F2F8;" fill-height class="d-flex flex-row">
		<Sidebar v-if="$store.userStore.state.isAuthenticated"/>
		<RouterContainer/>
	</v-container>
</template>

<script>
import Sidebar from './Sidebar'
import RouterContainer from './RouterContainer'

export default {
	name: 'MainLayout',
	components: { Sidebar, RouterContainer },
	data: () => ({}),
};
</script>
