<template>

		<div class="footer-dialog"  v-if="fullHtml" v-html="fullHtml" style="width: 100%"/>
</template>

<script>
export default {
	name: 'ActiveFooter',
	props: ['footer'],
	watch: {
		footer(newData) {
			console.log('1111111111', newData)
			if(newData){
				this.fullHtml = '<html><header><style>' + newData.css + '</style></header><body><footer>' + newData.html + '</footer></body><html/>'
			}
		}
	},
	data: () => ({
		fullHtml: null
	}),
	mounted(){
		console.log('222222222222', this.footer)
		if(this.footer){
			this.fullHtml = '<html><header><style>' + this.footer.css + '</style></header><body><footer>' + this.footer.html + '</footer></body><html/>'
		}
	},
	methods: {
	}
};
</script>

