import Vue from 'vue'
import Vuex from 'vuex'
import {
	getPages, 
	newPage,
	updatePage,
	deletePage 
} from '@/helpers/api/pages'
import Swal from 'sweetalert2'
import router from '../router'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		pages: null,
		totalPages: 0,
		loadingPages: false,
		loadingCreate: false,
		loadingUpdate: true
	},
	mutations: {
		setPages(state, pages){
			state.pages = pages
		},
		setTotalPages(state, totalPages){
			state.totalPages = totalPages
		},
		setLoadingPages(state, loading){
			state.loadingPages = loading
		},
		setCreate(state, loading){
			state.loadingCreate = loading
		},
		setUpdate(state, loading){
			state.loadingUpdate = loading
		},
	},
	actions: {
		async getPagesIntern({commit}, query){
			commit('setLoadingPages', true)
			const resp = await getPages(query)
			if(resp.success){
				commit('setPages', resp.data)
				// commit('setTotalPages', resp.total)
			}
			commit('setLoadingPages', false)
		},
		async createPage({commit},query){
			commit('setCreate', true)

			const {title, url, html, css, newImg} = query;
			const data = {title: title.text, url: url.text, html, css, image:newImg }

			const resp = await newPage(data)

			if(resp.success){
				Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })
			}
			else{
				Swal.fire({ icon: 'error', color: '#5b4492', title: 'Hubo un error al crear la página, por favor intenta nuevamente', showConfirmButton: false, timer: 1500 })
			}
			commit('setCreate', false)
			router.push({ name: 'PagesList' })
			
		},
		async updatedPage({commit}, {form, id}){
			commit('setCreate', true)
            
			const {title, url, html, css, newImg} = form;
			const data = {_method: "PATCH",id, title: title.text, url: url.text, html, css, image:newImg}

			const resp = await updatePage(data)

			if(resp.success){
				Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })
			}
			else{
				Swal.fire({ icon: 'error', color: '#5b4492', title: 'Hubo un error al tratar de actualizar la página, por favor intenta nuevamente', showConfirmButton: false, timer: 1500 })
			}
			commit('setCreate', false)
			router.push({ name: 'PagesList' })
			
		},
		async deletePage({ dispatch }, query) {


            const resp = await deletePage(query)

            if(resp.success){
				Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })
			}
			else{
				Swal.fire({ icon: 'error', color: '#5b4492', title: 'Hubo un error al tratar eliminar la página', showConfirmButton: false, timer: 1500 })
			}

            dispatch('getPagesIntern')

        },
	}
})

export default store