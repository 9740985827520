import {endpoints} from './endpoints'
import request from '../request'

export function getMultimediaLinks(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getMultimediaLinks,
			method: 'get',
			params: { data }
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function createMultimediaLink(data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		Object.keys(data).forEach((key) => {
			if (data[key]) {
				formData.append(key, data[key])
			}
		})
		request({
			url: endpoints.createMultimediaLink,
			method: 'post',
			data: formData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function deleteMultimediaLink(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteMultimediaLink + '/' + data.id,
			method: 'delete'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function updateMultimediaLink(data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		Object.keys(data).forEach((key) => {
			if (data[key]) {
				formData.append(key, data[key])
			}
		})
		formData.append('_method', 'PATCH')
		request({
			url: endpoints.updateMultimediaLink + '/' + data.id,
			method: 'post',
			data: formData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}