<template>
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <!-- header -->
    <Title 
    title='Enlaces destacados'  
    @link="goTo()"
    />

    <v-divider class="mb-5" style="width: 100%"></v-divider>

    <div style="width: 100%">
      <v-form ref="form" v-model="valid" lazy-validation>
        <TextField :fields="formItem.name" />
        <TextField :fields="formItem.url" />

        <v-row align="center">
          <v-col cols="12" sm="6">
            <v-file-input
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp, image/jpg"
              label="Imagen Link"
              @change="imgSelected"
            ></v-file-input>
          </v-col>

          <v-col v-if="$route.params.item" cols="12" sm="6">
            <v-img
              v-if="!url"
              contain
              :aspect-ratio="14 / 5"
              :src="$route.params.item.image"
              width="300"
            />

            <v-img contain v-if="url" :aspect-ratio="14 / 5" :src="url" width="300" />
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox v-model="formItem.active" label="Activo"></v-checkbox>
          </v-col>
        </v-row>

        <ButtonForm @submit="handleFeatured" />
      </v-form>
    </div>
  </v-container>
</template>

<script>
import TextField from "@/components/global/form/TextField";
import ButtonForm from "@/components/global/form/ButtonForm";
import Title from "@/components/ui/Title";
// import Swal from "sweetalert2";

export default {
  components: {
    TextField,
    ButtonForm,
     Title 
  },
  data: () => ({
    valid: true,
    newImgBase64: null,
    url: null,
    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        'El tamaño de la imagen debe ser inferior a 2 MB".',
    ],
    formItem: {
      newImg: null,
      active: false,
      name: {
        label: "Nombre",
        text: "",
        nameRules: [
          (v) => !!v || "El nombre es requerido",
          (v) =>
            (v && v.length <= 50) ||
            "El nombre debe tener menos de 50 caracteres",
        ],
      },
      url: {
        label: "Link",
        text: "",
        nameRules: [
          (v) => !!v || "El link es requerido",
          (v) =>
            (v && v.length <= 50) ||
            "El link debe tener menos de 50 caracteres",
        ],
      },
    },
  }),
  created() {
    const item = this.$route.params.item;
    if (item) {
      this.formItem = {
        newImg: null,
        active: item.active,
        name: {
          label: "Nombre",
          text: item.name,
          nameRules: [
            (v) => !!v || "El nombre es requerido",
            (v) =>
              (v && v.length <= 50) ||
              "El nombre debe tener menos de 50 caracteres",
          ],
        },
        url: {
          label: "Link",
          text: item.url,
          nameRules: [
            (v) => !!v || "El link es requerido",
            (v) =>
              (v && v.length <= 50) ||
              "El link debe tener menos de 50 caracteres",
          ],
        },
      };
    }
  },
  methods: {
    imgSelected(img) {
      this.formItem.newImg = img;
      this.getBase64(this.formItem.newImg);
      if(img) {
        this.url = URL.createObjectURL(img);
      }
    },
    getBase64(file) {
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onload = () => {
        this.newImgBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    goTo() {
     this.$router.push('featured-list')
    },
    reset() {
      this.$refs.form.reset();
    },
    handleFeatured() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) return;

      // const values = this.$store.featuredStore.state.featuredLinks.filter(
      //   (link) => link.active === true
      // );


      if(!this.$route.params.item) {
        // if (values.length >= 3 && this.formItem.active === true)
        //   return Swal.fire({
        //     icon: "error",
        //     color: "#5b4492",
        //     title: "Error",
        //     text: "No pueden haber mas de 3 enlaces activos",
        //     customClass: {
        //       title: "font-family: Roboto, seri",
        //     },
        //   });
        this.createFeatured(this.formItem)
      }else {
          this.updateFeatured(this.formItem)
      }
    },
    createFeatured(item) {
      this.$store.featuredStore.dispatch("createLink", { form: item });
    },
    updateFeatured(item) {
      this.$store.featuredStore.dispatch("updateLink", {
        form: item,
        id: this.$route.params.item.id,
      });
    },
  },
};
</script>
