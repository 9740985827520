<template>
	<v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
		<h2>Dashboard</h2>
		<v-divider class="mb-5" style="width: 100%;"></v-divider>
		<div style="width: 100%;">
			<Click2CallInfo />
		</div>
	</v-container>
</template>

<script>
import Click2CallInfo from './Click2Call/Index'

export default {
	name: 'DashboardIndex',
	components: {Click2CallInfo},
	data: () => ({
	}),
	mounted(){
	}
};
</script>
