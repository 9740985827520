import Vue from 'vue'
import VueRouter from 'vue-router'

import userStore from '@/stores/UserStore'

import Login from '@/components/Pages/Login'
import Dashboard from '@/components/Views/Dashboard/Dashboard'
import PostsList from '@/components/Views/Posts/List'
import Post from '@/components/Views/Posts/Post'
import Sliders from '@/components/Views/Sliders/List'
import Slider from '@/components/Views/Sliders/Slider'
import Form from '@/components/Views/navigations/Form'
import ListNavigations from '@/components/Views/navigations/List'
import Footer from '@/components/Views/footer/Footer'
import TableFooter from '@/components/Views/footer/TableFooter'
import SubMenu from '@/components/Views/navigations/SubMenu'
import LastMenu from '@/components/Views/navigations/LastMenu'
import FeaturedTable from '@/components/Views/Featured'
import Featured from '@/components/Views/Featured/Featured'
import Logo from '@/components/Views/Logo'
import HeaderList from '@/components/Views/Header'
import Header from '@/components/Views/Header/Header'
import PagesList from '@/components/Views/InternalPages'
import Page from '@/components/Views/InternalPages/Page'
import FileManager from '@/components/Views/FileManager/Index'
import Multimedia from '@/components/Views/Multimedia/Index'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history', // https://router.vuejs.org/api/#mode
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes(){
	return [
    {
      path: '/login',
      name: 'Login',
      meta: { label: 'Login' },
      component: Login
    },
    {
      path: '/',
      name: 'Dashboard',
      meta: { label: 'Dashboard' },
      component: Dashboard
    },
    {
      path: '/posts',
      name: 'Posts',
      meta: { label: 'Posts' },
      component: PostsList
    },
    {
      path: '/post',
      name: 'Post',
      meta: { label: 'Post' },
      component: Post
    },
    {
      path: '/sliders',
      name: 'Sliders',
      meta: { label: 'Sliders' },
      component: Sliders
    },
    {
      path: '/slider',
      name: 'Slider',
      meta: { label: 'Slider' },
      component: Slider
    },
    {
      path: '/navigations',
      name: 'Navigations',
      meta: { label: 'Items de navegación' },
      component: ListNavigations
    },
    {
      path: '/navigation',
      name: 'Navigation',
      meta: { label: 'Navegación' },
      component: Form
    },
    {
      path: '/submenu',
      name: 'Submenu',
      meta: { label: 'Submenu' },
      component: SubMenu 
    },
    {
      path: '/lastmenu',
      name: 'Lastmenu',
      meta: { label: 'LastMenu' },
      component: LastMenu
    },
    {
      path: '/table-footer',
      name: 'FooterTable',
      meta: { label: 'Pie de página' },
      component: TableFooter 
    },
    {
      path: '/footer',
      name: 'Footer',
      meta: { label: 'Pie de página' },
      component: Footer
    },
    {
      path: '/featured-list',
      name: 'FeaturedList',
      meta: { label: 'Enlaces destacados' },
      component: FeaturedTable 
    },
    {
      path: '/featured',
      name: 'Featured',
      meta: { label: 'Enlaces destacados' },
      component: Featured 
    },
    {
      path: '/logo',
      name: 'Logo',
      meta: { label: 'Logo página' },
      component: Logo 
    },
    {
      path: '/heder-list',
      name: 'HeaderList',
      meta: { label: 'Encabezado de página' },
      component: HeaderList
    },
    {
      path: '/header',
      name: 'Header',
      meta: { label: 'Encabezado de página' },
      component: Header 
    },
    {
      path: '/pages-list',
      name: 'PagesList',
      meta: { label: 'Páginas internas' },
      component: PagesList 
    },
    {
      path: '/page',
      name: 'Page',
      meta: { label: 'Páginas internas' },
      component: Page 
    },
    {
      path: '/file-manager',
      name: 'FileManager',
      meta: { label: 'Administrador de Archivos' },
      component: FileManager
    },
    {
      path: '/multimedia',
      name: 'Multimedia',
      meta: { label: 'Contenido Multimedia' },
      component: Multimedia
    },
	]
}

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'Register' && to.name !== 'Reestablecer' && to.name !== 'NewUserPassword' && !userStore.state.isAuthenticated) next({ name: 'Login' })
  else if ((to.name === 'Login' || to.name === 'Register' || to.name === 'NewUserPassword') && userStore.state.isAuthenticated) next({ name: 'Dashboard' })
  else next()
})

export default router