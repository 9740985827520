import Vue from 'vue'
import Vuex from 'vuex'
import Swal from 'sweetalert2'
import router from '../router'
import { newColumn, getColums } from '@/helpers/api/footer'
import request from '../helpers/request';



Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		footer: null,
		columns: [],
		loadingFooter: true,
		loadingColumns: true,
		loadingUpdate: true
	},
	mutations: {
		setFooter(state, value) {
			state.footer = value
		},
		setColums(state, value) {
			state.columns = value
		},
		setLoadingFooter(state, loading) {
			state.loadingFooter = loading
		},
		setLoadingColums(state, loading) {
			state.loadingColumns = loading
		},
	},
	actions: {
		async createColumn({ commit }, query) {
			commit('setLoadingFooter', true)

			const { html, css } = query
			const resp = await newColumn({ html, css })
			query.overlay = false
			Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message || 'hola', showConfirmButton: false, timer: 1500 })

			commit('setLoadingFooter', false)

			router.push({ name: 'FooterTable' })


		},
		async updateColumn({ commit },query) {

			commit('setLoadingFooter', true)
		
			const { footer, id } = query
			console.log(query)
			query.overlay = false
			const resp = await request.patch(`footer/${id}`, footer)
			
			Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

			commit('setLoadingFooter', false)

			router.push({ name: 'FooterTable' })


		},
		async deleteColumn({dispatch},query) {

		
			const resp = await request.delete(`footer/${query}`)
			
			Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })
            
			dispatch('getColumsFooter')

		},
		async getColumsFooter({ commit }) {
			commit('setLoadingColums', true)

			const resp = await getColums()
	
			if (resp.success) {
				commit('setColums', resp.data)

			}
			commit('setLoadingColums', false)
		}

	}
})

export default store