<template>
  <v-btn
    depressed
    class="mt-10"
    color="#4D3489"
    dark
    block
    @click="$emit('submit')"
  >
    <p class="mb-0 ml-2 body-2">
      {{preImage ? 'Actualizar' : 'Confirmar'}}
    </p>
  </v-btn>
</template>

<script>
export default {
 props: {
  preImage: {
    type: Boolean,
    default: true
  }
 }
}
</script>

<style>

</style>