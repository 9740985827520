import Vue from 'vue'
import Vuex from 'vuex'
import Swal from 'sweetalert2'
import router from '../router'
// import { newColumn, getColums } from '@/helpers/api/footer'
import request from '../helpers/request';



Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        headerLinks: null,
        loadingHeader: true,
        loadingCreate: true,
        loadingUpdate: true
    },
    mutations: {
        setHeaderLinks(state, value) {
            state.headerLinks = value
        },
        setLoadingHeader(state, loading) {
            state.loadingHeader = loading
        },
        setLoadingCreate(state, loading) {
            state.loadingCreate = loading
        },
        setLoadingUpdate(state, loading) {
            state.loadingUpdate = loading
        },

    },
    actions: {
        async createHeader({ commit }, { form }) {

            try {

                commit('setLoadingCreate', true)
                const { title, subtitle, url, icon, active } = form;
                const data = {title: title.text, subtitle: subtitle.text, url: url.text, icon: icon.text, active: +active }

                const resp = await request.post('header', data)

                if (resp.success) {
                    Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

                }

                commit('setLoadingCreate', false)

                router.push({ name: 'HeaderList' })
            } catch (error) {
                console.log(error)
            }


        },
        async updateHeader({ commit }, { form, id }) {

            try {
                commit('setLoadingUpdate', true)

                const { title, subtitle, url, icon, active } = form;
                const data = {title: title.text, subtitle: subtitle.text, url: url.text, icon: icon.text, active: +active  }


                const resp = await request.patch(`header/${id}`, data)


                Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

                commit('setLoadingUpdate', false)

                router.push({ name: 'HeaderList' })

            } catch (error) {
                console.log(error)
            }


        },
        async deleteHeader({ dispatch }, query) {


            const resp = await request.delete(`header/${query}`)

            Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

            dispatch('getHeaderLinks')

        },
        async getHeaderLinks({ commit }) {
            commit('setLoadingHeader', true)

            const resp = await request.get(`header`)
         
            if (resp.success) {
                commit('setHeaderLinks', resp.data)

            }
            commit('setLoadingHeader', false)
        }

    }
})

export default store