<template>
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <div class="d-flex align-center">
      <h2>Enlaces destacados</h2>
      <v-btn
        depressed
        dark
        small
        color="#4D3489"
        class="ml-4"
        @click="$router.push({ name: 'Featured', params: { level: 1, type: 1 } })"
      >
        <v-icon small>mdi-plus</v-icon>
        <p class="mb-0 ml-2">Crear item</p>
      </v-btn>
    </div>
    <v-divider class="mb-5" style="width: 100%"></v-divider>

      <Progress
      :data="$store.featuredStore.state.featuredLinks"
      />

   <!-- data table --> 
   <Table 
    :headers="headers"
    :data="$store.featuredStore.state.featuredLinks"
    :loadingData="$store.featuredStore.state.loadingFeatured"
    @update="updateItem"
    @delete="deleteItem"
   />

 

  </v-container>
</template>

<script>

import Table from '../../global/Table';
import Progress from '../../global/Progress';

export default {
    components: {Table, Progress},
    data() {
    return {
      loadingDelete: false,
      headers: [
        { text: "Imagen", value: "image" },
        { text: "Nombre", value: "name" },
        { text: "Fecha de actualización", value: "created_at" },
        { text: "Activo", value: "active" },
        // { text: "Posición", value: "position" },
        // { text: 'Sub menu', value: 'sidebar_items' },
        { text: "Acciones", value: "actions" },
      ],
    };
  },

    methods: {
    getLinks() {
      this.$store.featuredStore.dispatch("getFeaturedLinks");
    },
    updateItem(item) {
      this.$router.push({ name: "Featured", params: {item:item}});
    },
    deleteItem(id) {
      this.$store.featuredStore.dispatch("deleteLink", id);
    },
  },
//   created() {
//     this.getLinks()
//   },
  mounted() {
     this.getLinks() 
  }
}
</script>

<style scoped>
.post-table {
  width: 100%;
}
.post-table >>> tbody tr :hover {
  cursor: pointer;
}
</style>
