<template>
	<v-list-item link class="menu-item-inner d-flex align-center" :class="currentRouteName === item.routeName ? 'active':''"
		:to="{name: item.routeName}" :style="!item.routeName ? 'opacity: 0.9':''" :inactive="!item.routeName">
		<v-icon class="menu-item-icon">{{item.icon}}</v-icon>
		<p class="mb-0 ml-2 menu-item-text body-2">{{item.name}}</p>
	</v-list-item>
</template>

<script>
export default {
	name: 'MenuItem',
	props: ['item'],
	data: () => ({}),
	computed: {
    currentRouteName() {
        return this.$route.name;
    }
	}
};
</script>

<style scoped>
	.menu-item-inner{
		position: relative;
		padding-left: 5px;
		width: 100%;
	}
	.menu-item-inner:hover, .menu-item-inner.active{
		background-color: #a292c7;
	}
	.menu-item-inner:hover:before, .menu-item-inner.active:before{
		content: '';
		width: 5px;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background-color: #4D3489;
		opacity: 1!important;
	}
	.menu-item-inner{
		padding-left: 10%;
	}
	.menu-item-inner .menu-item-text{
		color: #606060;
	}
	.menu-item-inner:hover .menu-item-icon,
	.menu-item-inner:hover .menu-item-text,
	.menu-item-inner.active .menu-item-icon,
	.menu-item-inner.active .menu-item-text{
		color: white;
	}
	.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before{display: none;}
</style>
