import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		sidebarStatus: false,
		menuSidebarStatus: false,
		sidebarMini: false,
		sidebarMenu: [
			{
				name: 'Panel de Control',
				routeName: 'Dashboard',
				icon: 'mdi-view-dashboard-outline',
				active: true
			},
			{
				name: 'Archivos',
				routeName: 'FileManager',
				icon: 'mdi-file',
				active: true
			},
			{
				name: 'Noticias',
				routeName: 'Posts',
				icon: 'mdi-newspaper',
				active: true
			},
			{
				name: 'Sliders',
				routeName: 'Sliders',
				icon: 'mdi-image-area',
				active: true
			},
			{
				name: 'Items de navegación',
				routeName: 'Navigations',
				icon: 'mdi-menu',
				active: true
			},
			// {
			// 	name: 'Pie de página',
			// 	routeName: 'Footer',
			// 	icon: 'mdi-page-layout-footer',
			// 	active: true
			// },
			{
				name: 'Pie de página',
				routeName: 'FooterTable',
				icon: 'mdi-page-layout-footer',
				active: true
			},
			{
				name: 'Enlaces destacados',
				routeName: 'FeaturedList',
				icon: 'mdi-link',
				active: true
			},
			{
				name: 'Opciones',
				routeName: 'Logo',
				icon: 'mdi-warehouse',
				active: true
			},
			{
				name: 'Cabecera de página',
				routeName: 'HeaderList',
				icon: 'mdi-page-layout-header',
				active: true
			},
			{
				name: 'Páginas internas',
				routeName: 'PagesList',
				icon: 'mdi-page-layout-body',
				active: true
			},
			{
				name: 'Contenido Multimedia',
				routeName: 'Multimedia',
				icon: 'mdi-image',
				active: true
			},
		]
	},
	mutations: {
		setSidebarStatus(state, status){
			state.sidebarStatus = status
		},
		setMenuSidebarStatus(state, status){
			state.menuSidebarStatus = status
		},
		toggleSidebarMini(state){
			state.sidebarMini = !state.sidebarMini
		}
	}
})

export default store