<template>
    <div>
        <v-col
          sm="12"
          md="12"
          lg="12"
        >
          <v-text-field
            v-model="field.text"
            :accept="field.accept"
            :label="field.label"
            :rules="field.nameRules"
            @change="$emit('test', field.text)"
            required
          ></v-text-field>
        </v-col>
    </div>
</template>

<script>
export default {
    name: 'txt',
    props:  ['fields'],
    data() {
        return {
            field: this.fields
        };
    },
    created() {
        this.$emit("fields", this.field);
    },
}
</script>

<style scoped>
</style>