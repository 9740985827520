<template>
	<v-container fluid style="background-color: #FFFFFF;" fill-height class="d-flex flex-column justify-center">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0">{{message}}</p>
		</v-snackbar>
		<img :src="require('../../assets/imgs/logo.png')" style="max-width: 250px; width: 100%;"/>
		<div class="login-form-container d-flex justify-center mt-8">
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				class="d-flex flex-column flex-grow-1"
				style="max-width: 400px;width: 100%;"
			>
				<v-text-field
					v-model="email"
					:rules="emailRules"
					label="Email"
					required
				></v-text-field>
				<v-text-field
					v-model="userPassword"
					:rules="passwordRules"
					label="Contraseña"
					required
					:append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>
				<v-btn
					:disabled="!valid"
					color="#4D3489"
					dark
					:loading="loadingLogin"
					@click="loginUser"
				>
					Ingresar
				</v-btn>
				<v-btn
					:disabled="!valid || loadingLogin"
					color="white"
					class="mt-4"
					small
					text
					@click="$router.push({name: 'Register'})"
				>
					Registra tu Cuenta
				</v-btn>
			</v-form>
		</div>
	</v-container>
</template>

<script>
import {login} from '@/helpers/api/user'

export default {
	name: 'Login',
	data: () => ({
		valid: true,
		showMessage: false,
		messageColor: 'error',
		message: '',
		email: 'administrador@valcar.cl',
		// email: 'administrador@valcar.cl',
		emailRules: [
			v => !!v || 'Email es obligatorio',
			v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
		],
		userPassword: 'admin2022ecentral',
		passwordRules: [
			v => !!v || 'Contraseña es requerida'
		],
		passType: true,
		loadingLogin: false
	}),
	mounted(){
		this.$store.userStore.commit('setIsAuthenticated', false)
	},
	methods: {
		validate () {
			this.$refs.form.validate()
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		setCompanyPermissions(){
			if(this.$store.projectsStore.state.selectedProject){
				const companyID = this.$store.projectsStore.state.selectedProject.company_id
				if(this.$store.userStore.state.userCompanies.length > 0){
					const found = this.$store.userStore.state.userCompanies.find((company) => {
						return company.company_id = companyID
					})
					if(found){
						this.$store.userStore.commit('setCompanyPermissions', found.capabilities)
						this.$store.userStore.commit('setSelectedCompany', found)
					}
				}
			}
		},
		loginUser () {
			this.loadingLogin = true
			login(this.email, this.userPassword)
				.then(async () => {
					this.loadingLogin = false
					this.$store.userStore.commit('setIsAuthenticated', true)
					this.$router.push({name: 'Dashboard'})
					await this.$store.projectsStore.dispatch('getSelectedProject')
					this.setCompanyPermissions()
				})
				.catch((error) => {
					this.loadingLogin = false
					this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
					if(error.response && error.response.status == 401){
						this.message = 'Email o contraseña incorrectos. Por favor verifica los datos ingresados.'
					}
					this.messageColor = 'warning'
					this.showMessage = true
				})
		},
	},
};
</script>
<style scoped>
	.login-form-container{
		width: 100%;
	}	
</style>
