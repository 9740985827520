import {endpoints} from './endpoints'
import request from '../request'

export function uploadImage(file, path) {
	return new Promise((resolve, reject) => {
		let formData = new FormData()
		formData.append("file", file)
		formData.append("path", path)
		request({
			url: endpoints.uploadFile,
			method: 'post',
			data: formData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function getFiles(){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getFiles,
			method: 'get'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function getFilesByPath(path){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getFilesByPath,
			method: 'get',
			params: { path }
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function deleteFile(data){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteFile,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}