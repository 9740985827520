var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('v-data-table',{staticClass:"elevation-1 post-table",attrs:{"headers":_vm.headers,"mobile-breakpoint":"","items":_vm.data,"server-items-length":_vm.data.length,"items-per-page":_vm.data.length,"loading":_vm.loadingData || _vm.loadingDelete,"loading-text":"Cargando ...","no-data-text":"No hay información","no-results-text":"Sin resultados"},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('v-img',{attrs:{"contain":"","aspect-ratio":14/5,"src":item.image,"width":"300"}}):_vm._e()]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [(item.icon)?_c('v-icon',{staticClass:"menu-item-icon"},[_vm._v(_vm._s(item.icon))]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDateTime")(item.created_at))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? 'Si' : 'No')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"orange"},on:{"click":function($event){return _vm.$emit('update', item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-update")])],1)]}}],null,true)},[_c('span',[_vm._v("Actualizar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.$emit('delete', item.id)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}}],null,false,2430492512)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }