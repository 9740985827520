<template>
  <div class="d-flex align-center">
    <h2>
      {{ title }}
    </h2>
    <v-btn
      depressed
      color="#4D3489"
      dark
      small
      class="ml-4"
      @click="$emit('link')"
    >
      <v-icon small>mdi-arrow-left</v-icon>
      <p class="mb-0 ml-2">Volver</p>
    </v-btn>
  </div>
</template>
		<!-- <v-divider class="mb-5" style="width: 100%;"></v-divider> -->
		
<script>
export default {
  props: ["title"],
};
</script>
		