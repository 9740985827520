import Vue from 'vue'
import Vuex from 'vuex'
import {getPosts} from '@/helpers/api/posts'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		posts: [],
		totalPosts: 0,
		loadingPosts: true
	},
	mutations: {
		setPosts(state, posts){
			state.posts = posts
		},
		setTotalPosts(state, totalPosts){
			state.totalPosts = totalPosts
		},
		setLoadingPosts(state, loading){
			state.loadingPosts = loading
		},
	},
	actions: {
		async getPosts(context, query){
			context.commit('setLoadingPosts', true)
			const posts = await getPosts(query)
			if(posts.code == 200){
				context.commit('setPosts', posts.posts)
				context.commit('setTotalPosts', posts.total)
			}
			context.commit('setLoadingPosts', false)
		}
	}
})

export default store