<template>
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <!-- header -->
    <div class="d-flex align-center justify-space-between" style="width: 100%">
      <div class="d-flex align-center">
        <h2>Crear Página</h2>
        <v-btn
          depressed
          color="#4D3489"
          dark
          small
          class="ml-4"
          @click="$router.push('pages-list')"
        >
          <v-icon small>mdi-arrow-left</v-icon>
          <p class="mb-0 ml-2">Volver</p>
        </v-btn>
      </div>
      <div>
        <v-btn
          depressed
          color="#4D3489"
          dark
          small
          class="mr-4"
          @click="testGrapess()"
        >
          <v-icon small>mdi-eye</v-icon>
          <p class="mb-0 ml-2">Previsualizar</p>
        </v-btn>
        <v-btn depressed color="#4D3489" dark small @click="setPage()">
          <p class="mb-0">
            {{ $route.params.item ? "Actualizar" : "Confirmar" }}
          </p>
        </v-btn>
      </div>
    </div>

    <v-divider class="mb-5" style="width: 100%"></v-divider>

    <Progress :data="!$store.pagesStore.state.loadingCreate" />

    <div style="width: 100%">
      <v-form ref="form" v-model="valid" lazy-validation>
        <TextField :fields="formItem.title" @test="changeTitle" />
        <TextField :fields="formItem.url" />

        <v-row align="center">
          <v-col cols="12" sm="6">
            <v-file-input
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp, image/jpg"
              label="Imagen Página"
              @change="imgSelected"
            ></v-file-input>
          </v-col>

          <v-col v-if="$route.params.item" cols="12" sm="6">
            <v-img
              v-if="!formItem.imageUrl"
              :aspect-ratio="14 / 5"
              :src="$route.params.item.image"
              width="300"
            />

            <v-img
              v-if="formItem.imageUrl"
              :aspect-ratio="14 / 5"
              :src="formItem.imageUrl"
              width="300"
            />
          </v-col>
          <!-- 
          <v-col cols="12" sm="6">
            <v-checkbox v-model="formItem.active" label="Activo"></v-checkbox>
          </v-col> -->
        </v-row>
        <div style="width: 100%">
          <v-overlay :value="overlay">
            <v-progress-circular
              :size="70"
              :width="4"
              color="#4D3489"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
          <div class="mt-10 " id="gjs">
            {{ formItem.html }}
          </div>
        </div>
        <v-dialog v-model="showPreview" width="700">
          <v-card>
            <v-btn icon color="grey" @click="() => (showPreview = false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="pb-5 px-4">
              <ActivePage v-if="formItem" :post="formItem" :prev="preGrapes" />
            </div>
          </v-card>
        </v-dialog>

        <ButtonForm :preImage="update" @submit="setPage" />
      </v-form>
    </div>
  </v-container>
</template>

<script>
import plugin from "grapesjs-table";
import TextField from "@/components/global/form/TextField";
import ButtonForm from "@/components/global/form/ButtonForm";
import "grapesjs/dist/css/grapes.min.css";
import Progress from "../../global/Progress";
import Swal from "sweetalert2";
import grapesjs from "grapesjs";
import { getPageId } from "@/helpers/api/pages";
import { uploadImage } from "@/helpers/api/files";
import { toSeoUrl } from "@/helpers/toSeoUrl";
import grapesjsWeb from "grapesjs-preset-webpage";
import ActivePage from "./ActivePage";

export default {
  components: {
    TextField,
    ButtonForm,
    ActivePage,
    Progress,
  },
  data: () => ({
    valid: true,
    overlay: true,
    update: false,
    newImgBase64: null,
    showPreview: false,
    preGrapes: {
      html: "",
      css: "",
    },
    url: null,
    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        'El tamaño de la imagen debe ser inferior a 2 MB".',
    ],
    formItem: {
      newImg: null,
      imageUrl: null,
      active: false,
      html: "",
      css: "",
      title: {
        label: "Titulo",
        text: "",
        nameRules: [
          (v) => !!v || "El titulo es requerido",
          (v) =>
            (v && v.length <= 50) ||
            "El titulo debe tener menos de 50 caracteres",
        ],
      },
      url: {
        label: "URL",
        text: "",
        nameRules: [(v) => !!v || "La URL es requerido"],
      },
    },
  }),

  created() {
    const resp = this.$route.params.item || this.$route.params.item;
    if (resp) {
      this.uploadImage = true;
      this.update = true;
      this.formItem = {
        newImg: resp.image,
        imageUrl: null,
        html: "",
        css: "",
        title: {
          label: "Titulo",
          text: resp.title,
          nameRules: [
            (v) => !!v || "El titulo es requerido",
            (v) =>
              (v && v.length <= 50) ||
              "El titulo debe tener menos de 50 caracteres",
          ],
        },
        url: {
          label: "URL",
          text: resp.url,
          nameRules: [(v) => !!v || "La URL es requerido"],
        },
      };
    } else {
      this.overlay = false;
    }
  },
  mounted() {
    const item = this.$route.params.item;
    if (item) {
      this.getPage(item.id);
    }
    this.setGrapesJsEditor();
  },
  methods: {
    async getPage(query) {
      this.overlay = true;
      const resp = await getPageId(query);
      if (resp.success) {
        this.formItem.html = resp.data.content.html;
        this.formItem.css = resp.data.content.css;
        setTimeout(() => {
          this.editor.setComponents(resp.data.content.html);
          this.editor.setStyle(resp.data.content.css);
        }, 10);
      }
      this.overlay = false;
    },
    imgSelected(img) {
      this.formItem.newImg = img;
      this.getBase64(this.formItem.newImg);
      if (img) {
        this.formItem.imageUrl = URL.createObjectURL(img);
        // this.url = URL.createObjectURL(img);
      }
    },
    getBase64(file) {
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onload = () => {
        this.newImgBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    testGrapess() {
      this.showPreview = true;
      // this.preGrapes.html = this.editor.getHtml()
      // this.preGrapes.css = this.editor.getCss()
      this.formItem.html = this.editor.getHtml();
      this.formItem.css = this.editor.getCss();
    },
    setPage() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) return;

      this.formItem.html = this.editor.getHtml();
      this.formItem.css = this.editor.getCss();

      if (!this.formItem.html)
        return Swal.fire({
          icon: "error",
          color: "#5b4492",
          title: "Error",
          text: "Debe agregar contenido a su pie de página",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            title: "font-family: Roboto, seri",
          },
        });

      if (!this.$route.params.item) {
        this.$store.pagesStore.dispatch("createPage", this.formItem);
      } else {
        this.$store.pagesStore.dispatch("updatedPage", {
          form: this.formItem,
          id: this.$route.params.item.id,
        });
      }
    },
    changeTitle(text) {
      console.log("t: ", text);
      if (this.formItem.url.text == "") {
        this.formItem.url.text = toSeoUrl(text);
      }
    },
    setGrapesJsEditor() {
      const self = this;

      this.editor = grapesjs.init({
        container: "#gjs",
        fromElement: true,
        height: "800px",
        // width: "100%",
        storageManager: false,
        forceClass: true,
        plugins: [grapesjsWeb, plugin],
        pluginsOpts: {
          [plugin]: {

          },
        },
        panels: { defaults: [] },
        protectedCss: "",
        // protectedCss: `.ddm-flex-cols {
        //   display: flex;
        //   flex-direction: row;
        //   margin: -16px;
        //   padding: 16px 0px;
        //   flex-wrap: wrap; }
        //   .ddm-flex-cols .ddm-flex-cols-element {
        //   flex-grow: 2;
        //   margin: 16px;
        //   flex-shrink: 0;
        //   flex-basis: 0; }`,
        assetManager: {
          uploadFile: async function (e) {
            const file = e.dataTransfer
              ? e.dataTransfer.files[0]
              : e.target.files[0];
            const upload = await uploadImage(file, 'pagesFiles');
            console.log(upload);
            if (upload.code == 200) {
              const url = upload.image_url;
              self.editor.AssetManager.add(url);
            }
          },
        },
      });

      //   this.editor.on("component:update", model => {
      //   console.log("New content selected", model.get("content"));
      //  });

      const prefix = "myprefix-";

      this.editor.on("selector:add", (selector) => {
        const name = selector.get("name");

        if (
          selector.get("type") ===
            this.editor.SelectorManager.Selector.TYPE_CLASS &&
          name.indexOf(prefix) !== 0
        ) {
          selector.set("name", prefix + name);
        }
      });

			this.editor.on('component:add', function(model) {
				if(model.attributes.tagName === 'img'){
					model.set('style', { 'max-width': '100%' })
				}
			})
        // this.getFilesList();
    },
    // async getFilesList(){
    // 		const files = await getFiles()
    // 		if(files.code == 200){
    // 			this.editor.AssetManager.add(files.files)
    // 		}
    // 	}
  },
};
</script>

<style >
.modal-create-btn button {
    height: 35px;
    border-radius: 10px;
    border: solid !important;
    cursor: pointer;
    padding: 0 10px;
    color: white !important;
}
.modal-table-row input, .modal-table-column input {
    flex: 1;
    height: 35px;
    border-radius: 10px;
    border: 1px solid #f5f5f5;
    padding: 2px 16px;
    color: white !important;
}
</style>