<template>
	<v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
		<div class="d-flex align-center" style="width: 100%">
			<h2>Contenido Multimedia</h2>
			<CreateModal />
			<UpdateModal
				v-if="updateData && showUpdate"
				:updateData="updateData"
				:showUpdate="showUpdate"
				:closeUpdate="closeUpdate"/>
		</div>
		<v-data-table
			:headers="headers"
			:items="$store.multimediaStore.state.multimediaElements"
			:items-per-page="15"
			:loading="$store.multimediaStore.state.loadingMultimediaElements"
			loading-text="Cargando ..."
			no-data-text="No hay información"
			no-results-text="Sin resultados"
			class="elevation-1 post-table mt-2"
			style="width: 100%"
		>
			<template v-slot:item.image="{ item }">
				<v-img :src="item.image" height="50" max-width="50" contain/>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-btn
					icon
					small
					color="orange"
					@click.stop="updateElement(item)"
				>
					<v-icon medium>mdi-pencil</v-icon>
				</v-btn>
				<v-btn
					icon
					small
					color="red"
					@click.stop="deleteItem(item.id)"
				>
					<v-icon medium>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import CreateModal from './Create'
import UpdateModal from './Update'

export default {
	name: 'FileManager',
	components: {CreateModal, UpdateModal},
	data: () => ({
		uploading: false,
		loadingMultimedia: false,
		files: [],
		headers: [
			{
				text: 'Nombre',
				align: 'start',
				sortable: false,
				value: 'name',
			},
			{ text: 'Imagen', value: 'image' },
			{ text: 'Url', value: 'url' },
			{ text: 'Acciones', value: 'actions' },
		],
		updateData: null,
		showUpdate: false
	}),
	mounted(){
		this.getFiles()
	},
	methods: {
		updateElement(element){
			this.updateData = element
			this.showUpdate = true
		},
		closeUpdate(){
			this.showUpdate = false
			this.updateData = null
		},
		async getFiles(){
			this.$store.multimediaStore.dispatch("getMultimediaElements")
		},
		deleteItem(item_id){
			this.$store.multimediaStore.dispatch("deleteMultimediaElement", {id: item_id})
		}
	}
}
</script>
