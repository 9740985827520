const endpoints = {
	login: '/auth/login',
	userInfo: '/user/info',
	newPost: '/posts/create',
	posts: '/posts/list',
	post: '/posts/post',
	update: '/posts/update',
	deletePost: '/posts/destroy',
	uploadFile: '/files/uploadFile',
	getFiles: '/files/getFiles',
	getFilesByPath: '/files/getFileByPath',
	deleteFile: '/files/deleteFile',
	sliders: '/sliders/list',
	newSlider: '/sliders/create',
	deleteSlider: '/sliders/destroy',
	getSlider: '/sliders/getSlider',
	updateSlider: '/sliders/update',
	newItemNavigation: '/sidebaritem',
	getSideBarItems: '/sidebaritem',
	deleteItem: '/sidebaritem',
	createColumnFooter: '/footer',
	getColums : '/footer',
	footerId: '/footer',
	pages: '/page',
	newPage: '/page',
	page: '/page',
	updatedPage: '/page',
	deletePage: '/page',
	getLayout: '/layout',
	getMultimediaLinks: '/multimediaLinks',
	createMultimediaLink: '/multimediaLinks',
	deleteMultimediaLink: '/multimediaLinks',
	updateMultimediaLink: '/multimediaLinks',
}
export {endpoints}