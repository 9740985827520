var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-start",attrs:{"pa-5":"","fluid":"","fill-height":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('h2',[_vm._v("Pie de página")]),_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","color":"#4D3489","dark":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'Footer', params: { level: 1, type: 1 } })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('p',{staticClass:"mb-0 ml-2"},[_vm._v("Crear item")])],1)],1),_c('v-divider',{staticClass:"mb-5",staticStyle:{"width":"100%"}}),_c('v-data-table',{staticClass:"elevation-1 post-table",attrs:{"headers":_vm.headers,"mobile-breakpoint":"","items":_vm.$store.footerStore.state.columns,"server-items-length":_vm.$store.footerStore.state.columns.length,"items-per-page":_vm.$store.footerStore.state.columns.length,"loading":_vm.$store.footerStore.state.loadingColumns || _vm.loadingDelete,"loading-text":"Cargando ...","no-data-text":"No hay información","no-results-text":"Sin resultados"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDateTime")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.updateItem(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-update")])],1)]}}],null,true)},[_c('span',[_vm._v("Actualizar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCol(item.id)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }