<template>
	<v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
		<div class="d-flex align-center">
			<h2>Sliders</h2>
			<v-btn
				depressed
				color="#4D3489"
				dark
				small
				class="ml-4"
				@click="$router.push({name: 'Slider', params: {newSlider: true}})"
			>
				<v-icon small>mdi-plus</v-icon>
				<p class="mb-0 ml-2">Crear Slider</p>
			</v-btn>
		</div>
		<v-divider class="mb-5" style="width: 100%;"></v-divider>
		<v-data-table
			:headers="headers"
			:items="$store.slidersStore.state.sliders"
			:items-per-page="15"
			:server-items-length="$store.slidersStore.state.totalSliders"
			:loading="$store.slidersStore.state.loadingSliders || loadingDelete"
			loading-text="Cargando ..."
			no-data-text="No hay información"
			no-results-text="Sin resultados"
			class="elevation-1 post-table"
			@pagination="changePagination"
			@click:row="clickedRow"
		>
			<template v-slot:item.img="{ item }">
				<v-img
					:aspect-ratio="14/5"
					:src="item.img"
					width="300"
				></v-img>
			</template>
			<template v-slot:item.created_at="{ item }">
				{{ item.created_at | toDateTime }}
			</template>
			<template v-slot:item.actions="{ item }">
				<v-btn
					icon
					small
					color="red"
					@click.stop="deleteSlider(item.id)"
				>
					<v-icon medium>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
    <v-snackbar
      :value="alert.show"
      absolute
      top
      :color="alert.type"
      outlined
      right
    >
      {{alert.message}}
    </v-snackbar>
	</v-container>
</template>

<script>
import {deleteSlider} from '@/helpers/api/sliders'

export default {
	name: 'PostList',
	data: () => ({
		loadingDelete: false,
		headers: [
			{
				text: 'Imagen',
				align: 'start',
				sortable: false,
				value: 'img',
			},
			{ text: 'Título', value: 'title' },
			{ text: 'Descripción', value: 'description' },
			{ text: 'Link', value: 'link' },
			{ text: 'Creación', value: 'created_at' },
			{ text: 'Acciones', value: 'actions' },
		],
		query: {
			page: 0,
			start: 0,
			limit: 15,
		},
		alert: {
			show: false,
			message: '',
			type: 'success'
		}
	}),
	mounted(){
	},
	methods: {
		openSliderModal(slider){
			this.updateSliderInfo = slider
			this.showSliderModal = true
		},
		closeSliderModal(){
			this.showSliderModal = false
		},
		changePagination(data){
			this.query = {
				page: data.page,
				start: data.pageStart,
				limit: data.itemsPerPage,
			}
			this.getSliders(this.query)
		},
		clickedRow(item){
			this.$router.push({name: 'Slider', params: {slider_id: item.id}})
		},
		getSliders(query){
			this.$store.slidersStore.dispatch('getSliders', query)
		},
		async deleteSlider(id){
			this.loadingDelete = true
			const destroySlider = await deleteSlider({id})
			if(destroySlider.code == 200){
				this.alert = {
					show: true,
					message: 'Slider eliminado correctamente',
					type: 'success'
				}
				this.getSliders(this.query)
			}
			else{
				this.alert = {
					show: true,
					message: 'Hubo un error al eliminar el slider, por favor intenta nuevamente',
					type: 'warning'
				}
			}
			this.loadingDelete = false
		}
	}
};
</script>

<style scoped>
	.post-table{width: 100%;}
	.post-table >>> tbody tr :hover {
		cursor: pointer;
	}
</style>