<template>
	<v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
		<div class="d-flex align-center" style="width: 100%">
			<h2>Administrador de Archivos</h2>
			<v-btn
				depressed
				color="#4D3489"
				dark
				small
				class="ml-4"
				:disabled="uploading"
				@click="onClickFileUpload"
			>
				<v-icon small>mdi-plus</v-icon>
				<p class="mb-0 ml-2">Subir Archivo</p>
			</v-btn>
			<input 
				ref="uploader" 
				class="d-none" 
				type="file" 
				@change="onFileChanged"
			>
		</div>
		<v-data-table
			:headers="headers"
			:items="files"
			:items-per-page="15"
			:loading="loadingFiles"
			loading-text="Cargando ..."
			no-data-text="No hay información"
			no-results-text="Sin resultados"
			class="elevation-1 post-table mt-2"
			style="width: 100%"
		>
			<template v-slot:item.file_size="{ item }">
				{{bytesToSize(item.file_size)}}
			</template>
			<template v-slot:item.actions="{ item }">
				<v-btn
					icon
					small
					color="red"
					@click.stop="deleteFile(item.id)"
				>
					<v-icon medium>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import { uploadImage, getFilesByPath, deleteFile } from "@/helpers/api/files"

export default {
	name: 'FileManager',
	data: () => ({
		uploading: false,
		loadingFiles: false,
		files: [],
		headers: [
			{
				text: 'Archivo',
				align: 'start',
				sortable: false,
				value: 'file_name',
			},
			{ text: 'Tamaño', value: 'file_size' },
			{ text: 'Url', value: 's3_path' },
			{ text: 'Acciones', value: 'actions' },
		]
	}),
	mounted(){
		this.getFiles()
	},
	methods: {
		async getFiles(){
			this.loadingFiles = true
			const files = await getFilesByPath('files/')
			console.log(files)
			this.files = files.files
			this.loadingFiles = false
		},
		async deleteFile(file_id){
			await deleteFile({file_id})
			this.getFiles()
		},
		bytesToSize(bytes) {
			var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			if (bytes == 0) return '0 Byte';
			var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
			return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
		},
		onClickFileUpload () {
			this.$refs.uploader.click()
		},
		async onFileChanged(e) {
			this.uploading = true
			const file = e.target.files[0]
			const upload = await uploadImage(file, 'files')
			if (upload.code == 200) {
				this.getFiles()
			}
			this.uploading = false
		}
	}
}
</script>

<style>
	.custom-file-browser .folders-tree-card .scroll-x{
		max-height: 600px;
    overflow-y: scroll;
	}
</style>