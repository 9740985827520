var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-start",attrs:{"pa-5":"","fluid":"","fill-height":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('h2',[_vm._v("Sub Menu")]),_c('v-chip',{staticClass:"ma-2 ml-4",attrs:{"color":"#5b4492","outlined":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$store.navigationStore.state.activeISubMenu.icon))]),_c('h2',[_vm._v(_vm._s(_vm.$store.navigationStore.state.activeISubMenu.name))])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","color":"#4D3489","dark":"","small":""},on:{"click":function($event){return _vm.$router.push({
          name: 'Navigation',
          params: {
            level: 2,
            id: _vm.$store.navigationStore.state.activeISubMenu.id,
            type: 2,
          },
        })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('h4',[_vm._v(" "+_vm._s(_vm.$store.navigationStore.state.activeISubMenu.sidebar_items.length > 0 ? "Crear Item" : "Crear Submenu")+" ")])],1)],1),_c('v-divider',{staticClass:"mb-5",staticStyle:{"width":"100%"}}),_c('v-data-table',{staticClass:"elevation-1 post-table",attrs:{"headers":_vm.headers,"mobile-breakpoint":"","items":_vm.$store.navigationStore.state.activeISubMenu.sidebar_items,"server-items-length":_vm.$store.navigationStore.state.activeISubMenu.sidebar_items.length,"items-per-page":_vm.$store.navigationStore.state.activeISubMenu.sidebar_items.length,"loading":_vm.$store.navigationStore.state.loadingItemNavigation || _vm.loadingDelete,"loading-text":"Cargando ...","no-data-text":"No hay información","no-results-text":"Sin resultados"},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"menu-item-icon"},[_vm._v(_vm._s(item.icon || ""))])]}},{key:"item.sidebar_items",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(item.sidebar_items.length)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"#5b4492"},on:{"click":function($event){$event.stopPropagation();return _vm.subMenuItem(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Submenu")])]),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","small":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.updateItem(item)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-update")])],1),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item.id)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }