<template>
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <!-- header -->
    <Title 
    title='Cabecera de página'  
    @link="goTo()"
    />

    <v-divider class="mb-5" style="width: 100%"></v-divider>

    <div style="width: 100%">
      <v-form ref="form" v-model="valid" lazy-validation>
        <TextField :fields="formItem.title" />
        <TextField :fields="formItem.subtitle" />
        <TextField :fields="formItem.url" />

        <v-row align="center">
          <v-col cols="12" sm="6">
						<div class="d-flex justify-center align-center" style="width: 100%">
							<v-menu bottom nudge-bottom offset-y :close-on-content-click="false">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										color="#4D3489"
										class="mr-3"
										dark
										small
										v-bind="attrs"
										v-on="on"
									>
										Seleccionar Icono
									</v-btn>
								</template>

								<icon-picker v-model="formItem.icon.text" />
							</v-menu>
							<v-text-field
								v-model="formItem.icon.text"
								:rules="formItem.icon.nameRules"
								label="Icono"
								required
							>
								<template v-slot:prepend-inner>
									<v-icon >{{formItem.icon.text}}</v-icon>
								</template>
							</v-text-field>
						</div>
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox v-model="formItem.active" label="Activo"></v-checkbox>
          </v-col>
        </v-row>

        <ButtonForm :preImage="active" @submit="handleHeader" />
      </v-form>
    </div>
  </v-container>
</template>


<script>
import TextField from "@/components/global/form/TextField";
import ButtonForm from "@/components/global/form/ButtonForm";
import Title from "@/components/ui/Title";
import IconPicker from "vuetify-icon-picker"
// import Swal from "sweetalert2";

export default {
  components: {
    TextField,
    ButtonForm,
    Title,
		IconPicker
  },
  data: () => ({
    valid: true,
    active: false,
    formItem: {
      active: false,
      title: {
        label: "Titulo",
        text: "",
        nameRules: [
          (v) => !!v || "El titulo es requerido",
          (v) =>
            (v && v.length <= 50) ||
            "El titulo debe tener menos de 50 caracteres",
        ],
      },
      subtitle: {
        label: "Subtítulo",
        text: "",
        nameRules: [
          (v) => !!v || "El subtítulo es requerido",
          (v) =>
            (v && v.length <= 50) ||
            "El subtítulo debe tener menos de 50 caracteres",
        ],
      },
      url: {
        label: "Link",
        text: "",
        nameRules: [
          (v) => !!v || "El link es requerido",
          (v) =>
            (v && v.length <= 400) ||
            "El link debe tener menos de 400 caracteres",
        ],
      },
      icon: {
        label: "Icono",
        text: "",
        nameRules: [
          (v) => !!v || "El icono es requerido",
          (v) =>
            (v && v.length <= 50) ||
            "El nombre del icono debe tener menos de 50 caracteres",
        ],
      },
    },
  }),
  created() {
    const item = this.$route.params.item;
    if (item) {
      this.active = true;
      this.formItem = {
        newImg: null,
        active: item.active,
        title: {
          label: "Title",
          text: item.title,
          nameRules: [
            (v) => !!v || "El title es requerido",
            (v) =>
              (v && v.length <= 50) ||
              "El title debe tener menos de 50 caracteres",
          ],
        },
        subtitle: {
          label: "Subtítulo",
          text: item.subtitle,
          nameRules: [
            (v) => !!v || "El subtítulo es requerido",
            (v) =>
              (v && v.length <= 50) ||
              "El subtítulo debe tener menos de 50 caracteres",
          ],
        },
        url: {
          label: "Link",
          text: item.url,
          nameRules: [
            (v) => !!v || "El link es requerido",
            (v) =>
              (v && v.length <= 400) ||
              "El link debe tener menos de 400 caracteres",
          ],
        },
        icon: {
          label: "Icono",
          text: item.icon,
          nameRules: [
            (v) => !!v || "El icono es requerido",
            (v) =>
              (v && v.length <= 50) ||
              "El icono debe tener menos de 50 caracteres",
          ],
        },
      };
    }
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    goTo() {
     this.$router.push('heder-list')
    },
    handleHeader() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) return;

      if (!this.$route.params.item) {
        this.$store.headerStore.dispatch("createHeader", {
          form: this.formItem,
        });
      } else {
        this.$store.headerStore.dispatch("updateHeader", {
          form: this.formItem,
          id: this.$route.params.item.id,
        });
      }
    },
  },
};
</script>