import {endpoints} from './endpoints'
import request from '../request'

export function login (email, pass) {
	return new Promise((resolve, reject) => {
		const data = {
			email: email,
			password: pass
		}
		request({
			url: endpoints.login,
			method: 'post',
			data
		})
			.then(async function (response) {
				setUserToken(response)
				await getUser()
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

function setUserToken (token) {
	localStorage.setItem('userToken', token.access_token)
}

export function getUser() {
	return new Promise((resolve) => {
		request({
			url: endpoints.userInfo,
			method: 'get'
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}