import Vue from 'vue'
import Vuex from 'vuex'
import {
	newNavigation,
	getItemsNavigations
} from '@/helpers/api/itemsNavigation'
import Swal from 'sweetalert2'
import router from '../router'
import request from '../helpers/request';

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		items: [],
		activeISubMenu: null,
		activeItem: null,
		loadingItemNavigation: true
	},
	mutations: {
		setItems(state, items) {
			state.items = items

		},
		setActiveSubMenu(state, items) {
			state.activeISubMenu = items
		},
		setActiveItem(state, item) {
			state.activeItem = item
		},
		setLoadingItem(state, loading) {
			state.loadingItemNavigation = loading
		},
	},
	actions: {
		async createItemNavigation({ commit }, query) {
			commit('setLoadingItem', true)
		
			const resp = await newNavigation(query)
			
			query.overlay = false
			Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message || 'hola', showConfirmButton: false, timer: 1500 })

			commit('setLoadingItem', false)

			query.reset()
			router.push({ name: 'Navigations' })
			// if(query.level === 2) {
			// 	commit('setActiveSubMenu', resp.data)
			// }
			// router.push({ name: query.level === 1 ? 'Navigations' : query.level === 2 ? 'Submenu' : 'Navigations' }) 
			
		},
		async getItemsNavigations({ commit }) {
			commit('setLoadingItem', true)
			const resp = await getItemsNavigations()
			if (resp.success) {
				commit('setItems', resp.data)
			}
			commit('setLoadingItem', false)
		},
		async deleteItemNavigation({ commit, dispatch }, id) {
			try {

				commit('setLoadingItem', true)

				const resp = await request.delete(`sidebaritem/${id}`)

				Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

				dispatch('getItemsNavigations')

				commit('setLoadingItem', false)

				router.push({ name: 'Navigations' })

			} catch (error) {
				console.log(error)
				commit('setLoadingItem', false)
			}
		},
		async updateItemNavigation({ commit, dispatch }, item) {
			try {

				commit('setLoadingItem', true)
				item.sidebar_item_type_id = 1
				item.level = item.level ? item.level:1
				const resp = await request.patch(`sidebaritem/${item.id}`, item)
				item.overlay = false
				Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

				dispatch('getItemsNavigations')
				commit('setLoadingItem', false)
				item.reset()
				router.push({ name: 'Navigations' })
			} catch (error) {
				console.log(error)
				commit('setLoadingItem', false)
			}
		}

	}
})

export default store