<template>

    <v-overlay :value="!data">
		<v-progress-circular
			:size="70"
			:width="4"
			color="#4D3489"
			indeterminate
		></v-progress-circular>
	</v-overlay>
  
</template>

<script>
export default {
    props: ["data"]

}
</script>

<style>

</style>