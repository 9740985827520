import {endpoints} from './endpoints'
import request from '../request'

export function getPosts(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.posts,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function newPost(newData) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		Object.keys(newData).forEach((key) => {
			if(newData[key]){
				formData.append(key, newData[key])
			}
		})
		request({
			url: endpoints.newPost,
			method: 'post',
			data: formData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function getPost(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.post,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function deletePost(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deletePost,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updatePost(updateData) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		Object.keys(updateData).forEach((key) => {
			if(updateData[key]){
				formData.append(key, updateData[key])
			}
		})
		request({
			url: endpoints.update,
			method: 'post',
			data: formData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}