import Vue from 'vue'
import Vuex from 'vuex'
import Swal from 'sweetalert2'
// import { newColumn, getColums } from '@/helpers/api/footer'
import request from '../helpers/request';



Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
      loadingUpdate: true,
    },
    mutations: {
        setLoadingUpdate(state, loading) {
            state.loadingUpdate = loading
        },
    },
    actions: {
        async updateLayout({ commit }, id) {

            try {
                commit('setLoadingUpdate', true)


                const resp = await request.patch(`layout/${id}`)


                Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

                commit('setLoadingUpdate', false)


            } catch (error) {
                console.log(error)
            }


        },

    }
})

export default store