import Vue from 'vue'
import Vuex from 'vuex'
import Swal from 'sweetalert2'
import router from '../router'
// import { newColumn, getColums } from '@/helpers/api/footer'
import request from '../helpers/request';



Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        featuredLinks: null,
        loadingFeatured: true,
        loadingCreate: true,
        loadingUpdate: true
    },
    mutations: {
        setFeaturedLinks(state, value) {
            state.featuredLinks = value
        },
        setLoading(state, loading) {
            state.loadingFeatured = loading
        },
        setLoadingCreate(state, loading) {
            state.loadingCreate = loading
        },
        setLoadingUpdate(state, loading) {
            state.loadingUpdate = loading
        },

    },
    actions: {
        async createLink({ commit }, { form }) {

            try {

                commit('setLoadingCreate', true)

                const { active, name, newImg, url } = form;
                const data = { name: name.text, url: url.text, active: +active }
                let resp;


                const formData = new FormData()
                if (newImg) {
                    formData.append("image", newImg)
                    resp = await request.post('featured', formData, {
                        params: data
                    })

                } else {
                     data.image = newImg
                    resp = await request.post('featured', data)
                }


                if (resp.success) {
                    Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

                }

                commit('setLoadingCreate', false)

                router.push({ name: 'FeaturedList' })
            } catch (error) {
                console.log(error)
            }


        },
        async updateLink({ commit }, { form, id }) {

            try {
                commit('setLoadingUpdate', true)

                const { active, name, newImg, url } = form;
                const data = { _method: "PATCH", name: name.text, url: url.text, active: +active, newImg }

                const formData = new FormData()

                Object.keys(data).forEach((key) => {
                    if (data[key]) {
                        formData.append(key, data[key])
                    }
                })


                // const formDataUpdate = new FormData()
                // formDataUpdate.append("image", newImg)

                // const resp = await request.post(`featured/${id}`, formDataUpdate, {
                //     params: data
                // })

                const resp = await request.post(`featured/${id}`, formData)


                Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

                commit('setLoadingUpdate', false)

                router.push({ name: 'FeaturedList' })

            } catch (error) {
                console.log(error)
            }


        },
        async deleteLink({ dispatch }, query) {


            const resp = await request.delete(`featured/${query}`)

            Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

            dispatch('getFeaturedLinks')

        },
        async getFeaturedLinks({ commit }) {
            commit('setLoading', true)

            const resp = await request.get(`featured`)
            if (resp.success) {
                commit('setFeaturedLinks', resp.data)

            }
            commit('setLoading', false)
        }

    }
})

export default store