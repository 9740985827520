import {endpoints} from './endpoints'
import request from '../request'

export function getSliders(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sliders,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function deleteSlider(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteSlider,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function getSlider(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getSlider,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function newSlider(newData) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		Object.keys(newData).forEach((key) => {
			if(newData[key]){
				formData.append(key, newData[key])
			}
		})
		request({
			url: endpoints.newSlider,
			method: 'post',
			data: formData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function updateSlider(updateData) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		Object.keys(updateData).forEach((key) => {
			if(updateData[key]){
				formData.append(key, updateData[key])
			}
		})
		request({
			url: endpoints.updateSlider,
			method: 'post',
			data: formData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}