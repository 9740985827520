<template>
  <div>
    <h2 class="mb-2">{{ post.title.text }}</h2>
    <v-img
      v-if="post.imageUrl"
      lazy-src="https://picsum.photos/id/11/10/6"
      :aspect-ratio="80 / 45"
      :src="post.imageUrl"
      class="mb-2"
    ></v-img>
    <v-img
      v-else
      lazy-src="https://picsum.photos/id/11/10/6"
      :aspect-ratio="80 / 45"
      :src="post.newImg"
      class="mb-2"
    ></v-img>
    <div v-if="fullHtml" v-html="fullHtml" style="width: 100%"></div>
  </div>
</template>

<script>
export default {
  name: "ActivePage",
  props: ["post", "prev"],
  data: () => ({
    fullHtml: null,
  }),
  watch: {
    post: {
      handler(newData) {
        if (newData) {
          this.fullHtml =
            "<html><header><style>" +
            newData.css +
            "</style></header><body>" +
            newData.html +
            "</body><html/>";
        }
      },
      deep: true,
    },
    // prev: {
    //   handler(newValue) {
    //     if (newValue) {
    //       this.fullHtml =
    //         "<html><header><style>" +
    //         newValue.css +
    //         "</style></header><body>" +
    //         newValue.html +
    //         "</body><html/>";
    //     }
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    if (this.post) {
      this.fullHtml =
        "<html><header><style>" +
        this.post.css +
        "</style></header><body>" +
        this.post.html +
        "</body><html/>";
    }
  },
  methods: {},
};
</script>