<template>
	<v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
		<div class="d-flex align-center">
			<h2>Slider</h2>
			<v-btn
				depressed
				color="#4D3489"
				dark
				small
				class="ml-4"
				@click="$router.push('Sliders')"
			>
				<v-icon small>mdi-arrow-left</v-icon>
				<p class="mb-0 ml-2">Volver</p>
			</v-btn>
		</div>
		<v-divider class="mb-5" style="width: 100%;"></v-divider>
		<div style="width: 100%;">
			<v-overlay :value="overlay">
				<v-progress-circular
					:size="70"
					:width="4"
					color="#4D3489"
					indeterminate
				></v-progress-circular>
			</v-overlay>
			<v-row>
				<v-col cols="12" md="7">
					<v-form v-model="valid" style="width: 100%;">
						<v-container>
							<v-row>
								<v-col cols="12">
									<v-file-input
										accept="image/*"
										label="Imagen Portada"
										@change="imgSelected"
										hint="Ten en cuenta que en la web la relación de aspecto será de 14:5. Guíate por el ejemplo al costado."
										persistent-hint
									></v-file-input>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-text-field
										v-model="slider.title"
										:counter="255"
										label="Título"
										required
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-text-field
										v-model="slider.description"
										:counter="255"
										label="Breve descripción"
										required
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-text-field
										v-model="slider.link"
										:counter="255"
										label="Link"
										required
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="6">
									<v-menu
										ref="menu"
										v-model="menuDate"
										:close-on-content-click="false"
										:return-value.sync="slider.start_at"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="slider.start_at"
												label="Fecha inicio"
												prepend-icon="mdi-calendar"
												readonly
												v-bind="attrs"
												v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="slider.start_at"
											no-title
											scrollable
											locale="es-cl"
										>
											<v-spacer></v-spacer>
											<v-btn
												text
												color="primary"
												@click="menuDate = false"
											>
												Cancelar
											</v-btn>
											<v-btn
												text
												color="primary"
												@click="$refs.menu.save(slider.start_at)"
											>
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
								</v-col>
								<v-col cols="6">
									<v-menu
										ref="menuend"
										v-model="menuDateEnd"
										:close-on-content-click="false"
										:return-value.sync="slider.start_at"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="slider.end_at"
												label="Fecha fin"
												prepend-icon="mdi-calendar"
												readonly
												v-bind="attrs"
												v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="slider.end_at"
											no-title
											scrollable
											locale="es-cl"
										>
											<v-spacer></v-spacer>
											<v-btn
												text
												color="primary"
												@click="menuDateEnd = false"
											>
												Cancelar
											</v-btn>
											<v-btn
												text
												color="primary"
												@click="$refs.menuend.save(slider.end_at)"
											>
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
								</v-col>
								<v-col cols="12">
									<v-btn
										depressed
										color="#4D3489"
										dark
										block
										@click="setPost()"
									>
										<p class="mb-0 ml-2 body-2">Confirmar</p>
									</v-btn>
									<v-alert
										v-if="alert.show"
										dense
										text
										:type="alert.type"
									>
										{{alert.message}}
									</v-alert>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-col>
				<v-col cols="12" md="5">
					<h3 class="mb-3">Así se verá en la web <small>(Con un tamaño mayor)</small></h3>
					<div class="post-preview-container">
						<v-row>
							<v-col cols="12">
								<v-img
									lazy-src="https://picsum.photos/id/11/10/6"
									:aspect-ratio="14/5"
									:src="newImgBase64 ? newImgBase64:slider.img"
									class="mb-2 image-container"
									:class="slider.link && slider.link != '' ? 'link-cursor':''"
									@click="openLink(slider.link)"
								>
									<div class="slider-info-container px-3 py-1"
										v-if="(slider.title && slider.title != '') || (slider.description && slider.description != '')">
										<h4 class="mb-0 white--text body-2" v-if="slider.title">{{slider.title}}</h4>
										<h4 class="mb-0 white--text caption" v-if="slider.description">{{slider.description}}</h4>
									</div>
								</v-img>
								
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
import {getSlider, updateSlider, newSlider} from '@/helpers/api/sliders'

export default {
	name: 'Slider',
	data: () => ({
		overlay: true,
		valid: false,
		menuDate: false,
		menuDateEnd: false,
		slider: {
			link: '',
			title: '',
			description: '',
			start_at: '',
			end_at: '',
			img: '',
			newImg: null
		},
		alert: {
			show: false,
			message: '',
			type: 'success'
		},
		loadingUploadImage: false,
		newImgBase64: null
	}),
	mounted(){
		const slider_id = this.$route.query.slider_id || this.$route.params.slider_id
		if(slider_id){
			this.getSlider({id: slider_id})
		}
		else if(this.$route.params.newSlider){
			this.slider = {
				link: '',
				title: '',
				description: '',
				start_at: this.$options.filters.toDate(new Date()),
				end_at: '',
				img: '',
				newImg: null
			}
			this.overlay = false
		}
		else{
			this.$router.push({name: 'Sliders'})
		}
	},
	methods: {
		async getSlider(query){
			this.overlay = true
			const slider = await getSlider(query)
			if(slider.code == 200){
				this.slider = slider.slider
			}
			this.overlay = false
		},
		openLink(link){
			window.open(link, '_blank').focus()
		},
		imgSelected(img){
			this.slider.newImg = img
			this.getBase64(this.slider.newImg)
		},
		getBase64(file) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				this.newImgBase64 = reader.result
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
			};
		},
		setPost(){
			if(this.$route.params.newSlider){
				this.createSlider()
			}
			else{
				this.updateSlider()
			}
		},
		async createSlider(){
			const slider = await newSlider(this.slider)
			if(slider.code == 200){
				this.alert = {
					show: true,
					message: 'Post creado correctamente',
					type: 'success'
				}
				this.slider = slider.slider
			}
			else{
				this.alert = {
					show: true,
					message: 'Hubo un error al crear el post, por favor intenta nuevamente',
					type: 'warning'
				}
			}
			setTimeout(() => {
				this.alert = {
					show: false,
					message: '',
					type: 'success'
				}
			}, 4000);
		},
		async updateSlider(){
			const update = await updateSlider(this.slider)
			if(update.code == 200){
				this.alert = {
					show: true,
					message: 'Post actualizado correctamente',
					type: 'success'
				}
				this.slider = update.slider
			}
			else{
				this.alert = {
					show: true,
					message: 'Hubo un error al actualizar, por favor intenta nuevamente',
					type: 'warning'
				}
			}
			setTimeout(() => {
				this.alert = {
					show: false,
					message: '',
					type: 'success'
				}
			}, 4000);
		}
	}
};
</script>

<style scoped>
	.post-preview-container{
		background-color: white;
		border: 1px solid rgb(216, 216, 216);
		border-radius: 10px;
		padding: 10px;
	}
	.content-preview{
		width: 100%;
	}
	/deep/ .content-preview img{
		width: 100%;
	}
	.slider-info-container{
		background-color: rgba(0, 0, 0, 0.4);
		position: absolute;
		bottom: 0;
		width: 100%;
	}
	.link-cursor{
		cursor: pointer;
	}
	.image-container .v-image__image.v-image__image--cover{
		background-size: 100% 100%;
	}
</style>