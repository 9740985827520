<template>
	<v-dialog
		v-model="showUpdate"
		width="500"
	>
		<v-card>
			<v-card-title class="white--text" style="background-color:#4D3489;">
				Actualizar Contenido Multimedia
			</v-card-title>

			<v-card-text class="py-4">
				<v-form
					ref="form"
					v-model="valid"
					lazy-validation
					class="d-flex flex-column flex-grow-1"
					style="width: 100%;"
				>
					<v-text-field
						v-model="name"
						:rules="requiredRules"
						label="Nombre"
						required
					></v-text-field>
					<v-text-field
						v-model="url"
						:rules="requiredRules"
						label="Url destino"
						required
					></v-text-field>
					<v-checkbox
						v-model="active"
						label="Activo"
					></v-checkbox>
					<v-file-input
						v-model="image"
						accept="image/png"
						label="Imagen"
						persistent-hint
						hint="La imagen debe ser un icono de color negro y fondo transparente (PNG). Selecciona este campo sólo si deseas cambiar la imagen."
					></v-file-input>
				</v-form>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="#4D3489"
					outlined
					small
					@click="closeUpdate"
					:disabled="$store.multimediaStore.state.loadingUpdate"
				>
					Cancelar
				</v-btn>
				<v-btn
					color="#4D3489"
					dark
					small
					@click="updateElement"
					:loading="$store.multimediaStore.state.loadingUpdate"
				>
					Actualizar Contenido Multimedia
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'CreateMultimedia',
	props: {
		updateData: {
			type: Object,
			required: true
		},
		showUpdate: {
			type: Boolean
		},
		closeUpdate: {
			type: Function,
			required: true
		}
	},
	data: () => ({
		valid: false,
		requiredRules: [
			v => !!v || 'Este campo es obligatorio'
		],
		id: null,
		name: '',
		url: '',
		active: true,
		image: null
	}),
	mounted () {
		this.id = this.updateData.id
		this.name = this.updateData.name
		this.url = this.updateData.url
		this.active = this.updateData.active
	},
	methods: {
		async updateElement(){
			if(this.$refs.form.validate()){
				const data = {
					id: this.id,
					name: this.name,
					url: this.url,
					active: this.active ? 1:0,
					image: this.image
				}
				await this.$store.multimediaStore.dispatch("updateMultimediaElement", data)
				this.closeUpdate()
			}
		}
	}
}
</script>