<template>
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <div class="d-flex align-center">
      <h2>Cabecera de página</h2>
      <v-btn
        depressed
        color="#4D3489"
        dark
        small
        class="ml-4"
        @click="$router.push({ name: 'Header', params: { level: 1, type: 1 } })"
      >
        <v-icon small>mdi-plus</v-icon>
        <p class="mb-0 ml-2">Crear item</p>
      </v-btn>
    </div>
    <v-divider class="mb-5" style="width: 100%"></v-divider>

         <Progress
      :data="$store.headerStore.state.headerLinks"
      />


   <!-- data table -->
   <Table 
    :headers="headers"
    :data="$store.headerStore.state.headerLinks"
    :loadingData="$store.headerStore.state.loadingHeader"
    @update="updateHeader"
    @delete="deleteHeader"
   />

 

  </v-container>
</template>

<script>

import Table from '../../global/Table';
import Progress from '../../global/Progress';

export default {
    components: {Table, Progress},
    data() {
    return {
      loadingDelete: false,
      headers: [
        { text: "Icono", value: "icon" },
        { text: "Titulo", value: "title" },
        { text: "Subtítulo", value: "subtitle" },
        { text: "Link", value: "url" },
        { text: "Creación", value: "created_at" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },

    methods: {
    getLinks() {
      this.$store.headerStore.dispatch("getHeaderLinks");
    },
    updateHeader(item) {
      this.$router.push({ name: "Header", params: {item:item}});
    },
    deleteHeader(id) {
      this.$store.headerStore.dispatch("deleteHeader", id);
    },
  },
  mounted() {
     this.getLinks() 
  }
}
</script>

<style scoped>
.post-table {
  width: 100%;
}
.post-table >>> tbody tr :hover {
  cursor: pointer;
}
</style>
