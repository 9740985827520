<template>
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <div class="d-flex align-center">
      <h2>Páginas internas</h2>
      <v-btn
        depressed
        color="#4D3489"
        dark
        small
        class="ml-4"
        @click="$router.push({ name: 'Page', params: {newPage: true} })"
      >
        <v-icon small>mdi-plus</v-icon>
        <p class="mb-0 ml-2">Crear Página</p>
      </v-btn>
    </div>
    <v-divider class="mb-5" style="width: 100%"></v-divider>

      <Progress
      :data="$store.pagesStore.state.pages"
      />

    <!-- data table -->
    <Table 
    :headers="headers"
    :data="$store.pagesStore.state.pages"
    :loadingData="$store.pagesStore.state.loadingPages"
    @update="updateItem"
    @delete="deleteItem"
   />
  </v-container>
</template>

<script>
import Table from '../../global/Table';
import Progress from '../../global/Progress';

export default {
   components: {Table, Progress},
  data() {
    return {
      loadingDelete: false,
      headers: [
        { text: "Título", value: "title" },
        { text: "URL", value: "url" },
        { text: "Fecha de creación", value: "created_at" },
        // { text: "Activo", value: "active" },
        // { text: "Posición", value: "position" },
        // { text: 'Sub menu', value: 'sidebar_items' },
        { text: "Acciones", value: "actions" },
      ],
      query: {
        // page: 0,
        start: 0,
        limit: 15,
      },
    };
  },
  methods: {
    getPages() {
      this.$store.pagesStore.dispatch("getPagesIntern");
    },
    updateItem(item) {
      this.$router.push({ name: "Page", params: { item: item } });
    },
    deleteItem(id) {
      this.$store.pagesStore.dispatch("deletePage", id);
    },
  },
  created() {
    this.getPages(this.query);
  },
};
</script>

<style scoped>
.post-table {
  width: 100%;
}
.post-table >>> tbody tr :hover {
  cursor: pointer;
}
</style>