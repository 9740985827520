<template>
  <v-container class="d-flex align-start" pa-5 fluid fill-height>
    <div style="width: 100%">

        <!-- header -->
        <HeaderInit title="Opciones" />
         <v-divider class="mb-5" style="width: 100%"></v-divider>
 
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row align="center" >
          <v-col cols="12" sm="6">
            <v-file-input
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp, image/jpg"
              label="Logo"
              :clearable="true"
              @change="imgSelected"
              @click:clear="test"
            ></v-file-input>
             <ButtonForm :preImage="active" @submit="handleLogo" />
          </v-col>

          <v-col cols="12" sm="6">

<v-card
    :loading="$store.logoStore.state.loadingLogo"
    class="mx-auto py-10 px-10"
    max-width="400"
  >
    <v-img
      v-if="!url && $store.logoStore.state.logo"
     contain
      class="white--text align-end"
      height="200px"
      :src="$store.logoStore.state.logo.image"
    >
      <!-- <v-card-title class="text--primary" >Logo municipalidad</v-card-title> -->
    </v-img>

    <v-img
    v-if="url"
     contain
      class="white--text align-end"
      height="400px"
      :src="url"
    >
      <!-- <v-card-title class="text--primary" >Logo municipalidad</v-card-title> -->
    </v-img>

    <!-- <v-card-subtitle class="pb-0">
      Number 10
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <div>Whitehaven Beach</div>

      <div>Whitsunday Island, Whitsunday Islands</div>
    </v-card-text> -->

    <!-- <v-card-actions>
      <v-btn
        color="orange"
        text
      >
        Share
      </v-btn>

      <v-btn
        color="orange"
        text
      >
        Explore
      </v-btn>
    </v-card-actions> -->
  </v-card>

          </v-col>

          <!-- <v-col cols="12" sm="6"> -->
            <!-- <v-img
              v-if="!url"
              :aspect-ratio="14 / 5"
              :src="$route.params.item.image"
              width="300"
            /> -->

            <!-- <v-img
              v-if="url"
              :aspect-ratio="14 / 5"
              :src="url"
              width="400"
              height="200"
            /> -->
          <!-- </v-col> -->

          <!-- <v-col v-if="$route.params.item" cols="12" sm="6">
            <v-img
              v-if="!url"
              :aspect-ratio="14 / 5"
              :src="$route.params.item.image"
              width="300"
            />

            <v-img v-if="url" :aspect-ratio="14 / 5" :src="url" width="300" />
          </v-col> -->
        </v-row>
        <v-row align="center"  >
          <v-col>
             <v-switch
             color="#4D3489"
              v-model="switch2"
              inset
              :label="`Diseño de página`"
            ></v-switch>
            <ButtonForm :preImage="active" @submit="handleLayout" />
          </v-col>
          <v-col>
            <v-card
    class="mx-auto py-10 px-10 cardt"
    max-width="400"
  >
    <v-img
     contain
      class="white--text align-end"
      height="200px"
      @click="showPreview = true"
        :src="require(!switch2 ? '@/assets/imgs/layout2.png' : '@/assets/imgs/layout-1.png')"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-card>
          </v-col>
        </v-row>
      </v-form>
    </div>
      <v-dialog v-model="showPreview" width="700">
      <v-card >
        <v-btn icon color="grey" @click="() => (showPreview = false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="pb-5 px-4">
        <v-img
     contain
      class="white--text align-end"
      height="800px"
      @click="showPreview = true"
      :src="require(!switch2 ? '@/assets/imgs/layout2.png' : '@/assets/imgs/layout-1.png')"
    >
    </v-img>

        </div>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
            <v-progress-circular
              :size="70"
              :width="4"
              color="#4D3489"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
  </v-container>
</template>

<script>
import ButtonForm from "@/components/global/form/ButtonForm";
import HeaderInit from "@/components/global/HeaderInit";
import { getLayout } from "@/helpers/api/options";
export default {
  components: { ButtonForm, HeaderInit },
  data: () => ({
    id:null,
    overlay: true,
    switch2: false,
    showPreview: false,
    valid: true,
    url: null,
    newImg: null,
    active: true,
    newImgBase64: null,
    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        'El tamaño de la imagen debe ser inferior a 2 MB".',
    ],
  }),
  created() {
      },
  mounted() {
      this.$store.logoStore.dispatch("getLogo");
      this.getLayoutActive()
    //   if(this.$store.logoStore.state.logo) {
    //        this.active = false
    //    }
  },
  methods: {
    imgSelected(img) {
      this.newImg = img;
      this.getBase64(this.newImg);
      if(img) {
          this.url = URL.createObjectURL(img);
      }
    },
    getBase64(file) {
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onload = () => {
        this.newImgBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error----: ", error);
      };
    },
    reset() {
      this.$refs.form.reset();
    },
    handleLogo() {
        if(this.$store.logoStore.state.logo){
          this.$store.logoStore.dispatch("updateLogo", {image:this.newImg, id: this.$store.logoStore.state.logo.id});
        }else {
            this.$store.logoStore.dispatch("createLogo", this.newImg);
        }
      this.reset()
    },
      handleLayout() {
        !this.switch2 ? this.id = 2 : this.id = 1
        this.$store.layoutStore.dispatch("updateLayout",this.id);

      // this.getLayoutActive()
    },
    test() {
      this.url = null;
      this.newImg = null;
      this.newImgBase64 = null;
    },
      async getLayoutActive() {
       this.overlay = true;
      const resp = await getLayout();
      resp.data.id === 2 ? this.switch2 = false : this.switch2 = true
      console.log(resp);
       this.overlay = false;
    },
  },
};
</script>

<style>
.cardt {
  cursor: pointer;
}
</style>