import Vue from 'vue'
import Vuex from 'vuex'
import { getMultimediaLinks, createMultimediaLink, deleteMultimediaLink, updateMultimediaLink } from '@/helpers/api/multimedia'



Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        multimediaElements: [],
        loadingMultimediaElements: true,
        loadingCreate: false,
        loadingUpdate: false,
				loadingDelete: false
    },
    mutations: {
        setMultimediaElements(state, value) {
            state.multimediaElements = value
        },
        setLoading(state, loading) {
            state.loadingMultimediaElements = loading
        },
        setLoadingCreate(state, loading) {
            state.loadingCreate = loading
        },
        setLoadingUpdate(state, loading) {
            state.loadingUpdate = loading
        },
				setLoadingDelete(state, loading) {
					state.loadingDelete = loading
			},
    },
    actions: {
			async getMultimediaElements({ commit }) {
					commit('setLoading', true)

					const resp = await getMultimediaLinks()
					if (resp.success) {
							commit('setMultimediaElements', resp.data)

					}
					commit('setLoading', false)
			},
			async createMultimediaElement({ commit, dispatch }, data) {
					commit('setLoadingCreate', true)
					await createMultimediaLink(data)
					dispatch('getMultimediaElements')
					commit('setLoadingCreate', false)
			},
			async deleteMultimediaElement({ commit, dispatch }, data) {
				commit('setLoadingDelete', true)
				await deleteMultimediaLink(data)
				dispatch('getMultimediaElements')
				commit('setLoadingDelete', false)
			},
			async updateMultimediaElement({ commit, dispatch }, data) {
				commit('setLoadingDelete', true)
				await updateMultimediaLink(data)
				dispatch('getMultimediaElements')
				commit('setLoadingDelete', false)
			}
    }
})

export default store