import {endpoints} from './endpoints'
import request from '../request'

export const getPages = (data) =>  {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.pages,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}


export const newPage = (newData) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		Object.keys(newData).forEach((key) => {
			if(newData[key]){
				formData.append(key, newData[key])
			}
		})
		request({
			url: endpoints.newPage,
			method: 'post',
			data: formData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export const getPageId = (data) => {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.page}/${data}`,
			method: 'get',
			// params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export const deletePage = (data) => {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.deletePage}/${data}`,
			method: 'delete',
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export const updatePage = (updateData) => {
	if(typeof updateData.image === 'string' ) {
		updateData.image = null
	}
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		Object.keys(updateData).forEach((key) => {
			if(updateData[key]){
				formData.append(key, updateData[key])
			}
		})
		request({
			url: `${endpoints.updatedPage}/${updateData.id}`,
			method: 'post',
			data: formData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}