<template>
  <v-app>
    <v-main>
      <Main/>
    </v-main>
  </v-app>
</template>

<script>
import Main from '@/components/Layout/Main'

export default {
  name: 'App',
  components: {
    Main,
  },
	computed: {
		userAuth() {
			return this.$store.userStore.state.isAuthenticated
		}
	},
	watch: {
		userAuth(newData, oldData) {
			if(newData && !oldData){
				this.getUserInfo()
			}
		}
	},
  data: () => ({
    //
  }),
	mounted(){
		this.$store.menuStore.commit('setMenuSidebarStatus', !this.$vuetify.breakpoint.mobile)
		if(this.$store.userStore.state.isAuthenticated){
			this.getUserInfo()
		}
	},
	methods: {
		getUserInfo(){
			this.$store.userStore.dispatch('getUserInfo')
		}
	}
};
</script>
