import {endpoints} from './endpoints'
import request from '../request'


export const newNavigation = (newData) => {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.newItemNavigation,
			method: 'post',
			data: newData
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}


export const getItemsNavigations = (data) => {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getSideBarItems,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}


export const deleteItem = (data) => {
	console.log('1111111111',data)
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteItem,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}