import Vue from 'vue'
import Vuex from 'vuex'
import Swal from 'sweetalert2'
// import { newColumn, getColums } from '@/helpers/api/footer'
import request from '../helpers/request';



Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        logo: null,
        loadingLogo: true,
        loadingCreateLogo: true,
        loadingUpdateLogo: true
    },
    mutations: {
        setLogo(state, value) {
            state.logo = value
        },
        setLoadingLogo(state, loading) {
            state.loadingLogo = loading
        },
        setLoadingCreateLogo(state, loading) {
            state.loadingCreateLogo = loading
        },
        setLoadingUpdateLogo(state, loading) {
            state.loadingUpdateLogo = loading
        },

    },
    actions: {
        async createLogo({ commit, dispatch }, logo) {

            try {

                commit('setLoadingCreateLogo', true)

                const formData = new FormData()
                formData.append("image", logo)

                const resp = await request.post('logo', formData)


                if (resp.success) {
                    Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

                }

                dispatch('getLogo')

                commit('setLoadingCreateLogo', false)

            } catch (error) {
                console.log(error)
            }


        },
        async updateLogo({ commit, dispatch }, { image, id }) {

            try {
                commit('setLoadingUpdateLogo', true)

                const data = { _method: "PATCH" }

                const formDataUpdate = new FormData()
                formDataUpdate.append("image", image)

                const resp = await request.post(`logo/${id}`, formDataUpdate, { params: data })

                Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

                dispatch('getLogo')

                commit('setLoadingUpdateLogo', false)


            } catch (error) {
                console.log(error)
            }


        },
        // async deleteLink({ dispatch }, query) {


        //     const resp = await request.delete(`featured/${query}`)

        //     Swal.fire({ icon: 'success', color: '#5b4492', title: resp.message, showConfirmButton: false, timer: 1500 })

        //     dispatch('getFeaturedLinks')

        // },
        async getLogo({ commit }) {
            commit('setLoadingLogo', true)

            const resp = await request.get(`logo/${1}`)
            if (resp.success) {
                commit('setLogo', resp.data)

            }
            commit('setLoadingLogo', false)
        }

    }
})

export default store