<template>
	<v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
		<v-row style="width: 100%;" v-if="info">
			<v-col cols="12">
				<div class="d-flex align-center justify-space-between">
					<v-img
						max-height="50"
						max-width="150"
						:src="require('@/assets/imgs/logoclick2callmini.png')"
					></v-img>
					<v-btn
						depressed
						color="#4D3489"
						dark
						small
						class="ml-4"
						href="https://panelclick2call.web.app" target="_blank"
					>
						<p class="mb-0 ml-2">Panel Click2Call</p>
					</v-btn>
				</div>
				<v-divider class="mt-2 mb-4"></v-divider>
			</v-col>
			<v-col cols="4">
				<v-card class="pa-5" color="#f2f2f2" shaped>
					<div class="d-flex align-center justify-space-between">
						<div class="d-flex align-center">
							<v-icon class="mr-2" color="#4D3489">mdi-monitor-dashboard</v-icon>
							<h4 style="color: #4D3489">Videollamadas Click2Call</h4>
						</div>
						<div style="background-color: #4D3489" class="rounded-lg py-1 px-2">
							<p class="white--text mb-0 caption text-right">Total: 22</p>
							<p class="white--text mb-0 caption text-right">Atendidas: 18</p>
						</div>
					</div>
					<v-divider class="mt-2 mb-4"></v-divider>
					<BarChart
						style="max-height: 200px;"
						:chartdata="{
							labels: ['11', '12', '13', '14','15', '16', '17'],
							datasets: [
								{
									label: 'Recibidas',
									backgroundColor: '#4D3489',
									data: info.videollamadas
								},
								{
									label: 'Atendidas',
									backgroundColor: '#075c07',
									data: info.videollamadasA
								}
							]
						}"
						:options="{
							responsive: true,
							maintainAspectRatio: false,
							legend: {
								display: false
							},
							tooltips: {
								callbacks: {
									label: function(tooltipItem) {
										return tooltipItem.yLabel;
									}
								}
							},
							scales: {
								xAxes: [{
									ticks: {
										fontColor: '#4D3489'
									},
									gridLines: {
										color: '#9783c5'
										}
								}],
								yAxes: [{
									ticks: {
										fontColor: '#4D3489',
										beginAtZero: true
									},
									gridLines: {
										color: '#9783c5'
									}
								}]
							}
						}"
					/>
				</v-card>
			</v-col>
			<v-col cols="4">
				<v-card class="pa-5" color="#f2f2f2" shaped>
					<div class="d-flex align-center justify-space-between">
						<div class="d-flex align-center">
							<v-icon class="mr-2" color="#4D3489">mdi-email-outline</v-icon>
							<h4 style="color: #4D3489">Videomails Click2Call</h4>
						</div>
						<div style="background-color: #4D3489" class="rounded-lg py-1 px-2">
							<p class="white--text mb-0 caption text-right">Total: 22</p>
							<p class="white--text mb-0 caption text-right">Atendidos: 18</p>
						</div>
					</div>
					<v-divider class="mt-2 mb-4"></v-divider>
					<BarChart
						style="max-height: 200px;"
						:chartdata="{
							labels: ['11', '12', '13', '14','15', '16', '17'],
							datasets: [
								{
									label: 'Recibidas',
									backgroundColor: '#4D3489',
									data: info.videollamadas
								},
								{
									label: 'Atendidas',
									backgroundColor: '#075c07',
									data: info.videollamadasA
								}
							]
						}"
						:options="{
							responsive: true,
							maintainAspectRatio: false,
							legend: {
								display: false
							},
							tooltips: {
								callbacks: {
									label: function(tooltipItem) {
										return tooltipItem.yLabel;
									}
								}
							},
							scales: {
								xAxes: [{
									ticks: {
										fontColor: '#4D3489'
									},
									gridLines: {
										color: '#9783c5'
									}
								}],
								yAxes: [{
									ticks: {
										fontColor: '#4D3489',
										beginAtZero: true
									},
									gridLines: {
										color: '#9783c5'
									}
								}]
							}
						}"
					/>
				</v-card>
			</v-col>
			<v-col cols="4">
				<v-card class="pa-5" color="#f2f2f2" shaped>
					<div class="d-flex align-center justify-space-between">
						<div class="d-flex align-center">
							<v-icon class="mr-2" color="#4D3489">mdi-phone</v-icon>
							<h4 style="color: #4D3489">Llamadas Telefónicas Click2Call</h4>
						</div>
						<div style="background-color: #4D3489" class="rounded-lg py-1 px-2">
							<p class="white--text mb-0 caption text-right">Total: 22</p>
							<p class="white--text mb-0 caption text-right">Atendidas: 18</p>
						</div>
					</div>
					<v-divider class="mt-2 mb-4"></v-divider>
					<BarChart
						style="max-height: 200px;"
						:chartdata="{
							labels: ['11', '12', '13', '14','15', '16', '17'],
							datasets: [
								{
									label: 'Recibidas',
									backgroundColor: '#4D3489',
									data: info.videollamadas
								},
								{
									label: 'Atendidas',
									backgroundColor: '#075c07',
									data: info.videollamadasA
								}
							]
						}"
						:options="{
							responsive: true,
							maintainAspectRatio: false,
							legend: {
								display: false
							},
							tooltips: {
								callbacks: {
									label: function(tooltipItem) {
										return tooltipItem.yLabel;
									}
								}
							},
							scales: {
								xAxes: [{
									ticks: {
										fontColor: '#4D3489'
									},
									gridLines: {
										color: '#9783c5'
									}
								}],
								yAxes: [{
									ticks: {
										fontColor: '#4D3489',
										beginAtZero: true
									},
									gridLines: {
										color: '#9783c5'
									}
								}]
							}
						}"
					/>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import BarChart from '@/components/Charts/Bar'

export default {
	name: 'Click2CallInfoIndex',
	components: {BarChart},
	data: () => ({
		info: {
			videollamadas: [2, 4, 8, 3, 10 , 15, 2],
			videollamadasA: [2, 3, 6, 1, 7 , 12, 2]
		}
	}),
	mounted(){
	}
};
</script>

<style scoped>
</style>
