<template>
    <v-data-table
      v-if="data"
      :headers="headers"
      mobile-breakpoint
      :items="data"
      :server-items-length="data.length"
      :items-per-page="data.length"
      :loading="loadingData || loadingDelete"
      loading-text="Cargando ..."
      no-data-text="No hay información"
      no-results-text="Sin resultados"
      class="elevation-1 post-table"
    >
     
      <template v-slot:item.image="{ item }">
         <v-img
         contain
            v-if="item.image"
            :aspect-ratio="14/5"
            :src="item.image"
            width="300"
            />
      </template>

       <template v-slot:item.icon="{ item }">
        <v-icon v-if="item.icon" class="menu-item-icon">{{ item.icon }}</v-icon>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | toDateTime }}
      </template>

       <template v-slot:item.active="{ item }">
        {{ item.active ? 'Si' : 'No'}}
      </template>


      <template v-slot:item.actions="{ item }">
        <v-row >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              small
              color="orange"
              @click="$emit('update', item)"
            >
              <v-icon medium>mdi-update</v-icon>
            </v-btn>
          </template>
          <span>Actualizar</span>
        </v-tooltip>

 <v-tooltip top>
          <template v-slot:activator="{ on }">
        <v-btn
          class="ml-3"
          icon
          small
          v-on="on"
          color="red"
          @click="$emit('delete', item.id)"
        >
          <v-icon medium>mdi-delete</v-icon>
        </v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>

        </v-row >

      </template>
    </v-data-table>
</template>

<script>


export default {
    // props: ["headers", "columns", "loadingColumns"],
    props: {
        data: {
            type: Array,
            default: null
        },
        headers: {
            type: Array,
            default: null
        },
        loadingData: {
            type: Boolean,
            default: true
        }
        
    },
    data() {
        return {
         loadingDelete: false,
        }
    },
}
</script>



