<template>
  <!-- <Table 
  v-bind:items="$route.params.item" 
  v-bind:routerItem="$router.push({ name: 'Navigation', params: { level: 2, id:$route.params.item.id, type: 2  }})"
  /> -->
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <div class="d-flex align-center">
      <h2>Sub Menu</h2>
       <v-chip
      class="ma-2 ml-4"
      color="#5b4492"
      outlined
    >
      <v-icon class="mr-1" >{{$route.params.item.icon}}</v-icon>
      <h2 >{{$route.params.item.name}}</h2>
    </v-chip>

        <v-btn
        depressed
        color="#4D3489"
        dark
        small
        class="ml-4"
        @click="$router.push({ name: 'Navigation', params: { level: 3, id:$route.params.item.id, type: 2  }})"
      >
        <v-icon small>mdi-plus</v-icon>
        <h4>
        {{$route.params.item.sidebar_items.length > 0 ? 'Crear Item' : 'Crear Submenu' }}
        
        </h4>
      </v-btn>

       <!-- <v-btn
        depressed
        color="#4D3489"
        dark
        small
        class="ml-4"
        @click="$router.push({name:'Submenu', params: {items:$route.params.item.sidebar_items}})"
      >
        <v-icon small>mdi-arrow-left</v-icon>
        <p class="mb-0 ml-2">Volver</p>
      </v-btn> -->


    </div>
    <v-divider class="mb-5" style="width: 100%"></v-divider>
    <v-data-table
      :headers="headers"
      mobile-breakpoint
      :items="$route.params.item.sidebar_items"
      :server-items-length="$route.params.item.length"
      :items-per-page="$route.params.item.length"
      loading-text="Cargando ..."
      no-data-text="No hay información"
      no-results-text="Sin resultados"
      class="elevation-1 post-table"
    >

      <template v-slot:item.icon="{ item }">
        <v-icon class="menu-item-icon">{{ item.icon }}</v-icon>
      </template>

       <template v-slot:item.sidebar_items="{ item }">
             <p class="font-weight-regular ">
             {{ item.sidebar_items.length }}
    </p>
      
      </template>
       
   

      <template v-slot:item.actions="{ item }">

        <v-btn class="ml-3"  icon small color="orange" @click.stop="updateItem(item)">
          <v-icon medium>mdi-update</v-icon>
        </v-btn>

        <v-btn class="ml-3" icon small color="red" @click.stop="deleteItem(item.id)">
          <v-icon medium>mdi-delete</v-icon>
        </v-btn>
      </template>

    </v-data-table>

  </v-container>
</template>

<script>


    // import Table from "./Table";

export default {
    // components: {Table},
    data() {

    return {
      loadingDelete: false,
      headers: [
        {
          text: "Icono",
          align: "start",
          sortable: false,
          value: "icon",
        },
        { text: "Nombre", value: "name" },
        { text: "Path", value: "path" },
        { text: "Posición", value: "position" },
        { text: 'Sub menu', value: 'sidebar_items' },
        { text: 'Acciones', value: 'actions' },
      ],
    };
  },
  // computed: {
  // ...mapState('navigationStore',['items'])

  // },
  methods: {
     updateItem(item) {
      this.$router.push({name: 'Navigation', params: {item}})
    },
    deleteItem(id) {
      this.$store.navigationStore.dispatch('deleteItemNavigation', id)
    },
  },
};
</script>

<style scoped>
.post-table {
  width: 100%;
}
.post-table >>> tbody tr :hover {
  cursor: pointer;
}
</style>