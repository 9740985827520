<template>
  <v-container class="d-flex flex-column align-start" pa-5 fluid fill-height>
    <div class="d-flex align-center">
      <h2>Items de navegación</h2>
      <v-btn
        depressed
        color="#4D3489"
        dark
        small
        class="ml-4"
        @click="$router.push({ name: 'Navigation', params: { level: 1 , type:1} })"
      >
        <v-icon small>mdi-plus</v-icon>
        <p class="mb-0 ml-2">Crear item</p>
      </v-btn>
      
    </div>
    <v-divider class="mb-5" style="width: 100%"></v-divider>
    <v-data-table
      :headers="headers"
      mobile-breakpoint
 
      :items="$store.navigationStore.state.items"
      :server-items-length="$store.navigationStore.state.items.length"
      :items-per-page="$store.navigationStore.state.items.length"
      :loading="
        $store.navigationStore.state.loadingItemNavigation || loadingDelete
      "
      loading-text="Cargando ..."
      no-data-text="No hay información"
      no-results-text="Sin resultados"
      class="elevation-1 post-table"
    >
      <!-- <v-data-footer
       items-per-page-options="$store.navigationStore.state.items.length"
      items-per-page-text='jkdzhkjfh'
      page-text="sdkjhfkjsdfh"
    >
    </v-data-footer> -->

      <template v-slot:item.icon="{ item }">
        <v-icon class="menu-item-icon">{{ item.icon }}</v-icon>
      </template>

       <template v-slot:item.sidebar_items="{ item }">
             <p class="font-weight-regular ">
             {{ item.sidebar_items.length }}
    </p>
      
      </template>
       
   

      <template v-slot:item.actions="{ item }">

        
        <v-tooltip top>
           <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon small color="#5b4492" @click.stop="subMenuItem(item)">
          <v-icon medium>mdi-eye</v-icon>
        </v-btn>
         </template>
         <span>Ver Submenu</span>
      </v-tooltip>
    
        <v-tooltip top>
           <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="ml-3"  icon small color="orange" @click.stop="updateItem(item)">
          <v-icon medium>mdi-update</v-icon>
        </v-btn>
          </template>
         <span>Actualizar</span>
      </v-tooltip>

        <v-tooltip top>
           <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="ml-3" icon small color="red" @click.stop="deleteItem(item.id)">
          <v-icon medium>mdi-delete</v-icon>
        </v-btn>
           </template>
         <span>Eliminar</span>
      </v-tooltip>
      </template>

    </v-data-table>

    <!-- <v-snackbar
      :value="alert.show"
      absolute
      top
      :color="alert.type"
      outlined
      right
    >
      {{alert.message}}
    </v-snackbar> -->
  </v-container>
</template>

<script>
// import {mapMutations} from 'vuex';

export default {
  data() {
    return {
      loadingDelete: false,
      headers: [
        {
          text: "Icono",
          align: "start",
          sortable: false,
          value: "icon",
        },
        { text: "Nombre", value: "name" },
        { text: "Path", value: "path" },
        { text: "Posición", value: "position" },
        { text: 'Sub menu', value: 'sidebar_items' },
        { text: 'Acciones', value: 'actions' },
      ],
    };
  },
  // computed: {
  // ...mapState('navigationStore',['items'])

  // },
  methods: {
    // ...mapMutations('navigationStore','setActiveItem'),
    getItems() {
      this.$store.navigationStore.dispatch("getItemsNavigations");
    },
     updateItem(item) {
      this.$router.push({name: 'Navigation', params: {item, level:2}})
      // this.$store.navigationStore.commit('setActiveSubMenu', item)
    },
    subMenuItem(item) {
      this.$router.push({name: 'Submenu', params: {item}})
      this.$store.navigationStore.commit('setActiveSubMenu', item)
    },
    deleteItem(id) {
      this.$store.navigationStore.dispatch('deleteItemNavigation', id)
    },
  },
  created() {
    this.getItems();
  },
};
</script>

<style scoped>
.post-table {
  width: 100%;
}
.post-table >>> tbody tr :hover {
  cursor: pointer;
}
</style>



