import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './stores/index'
import filters from './filters/filters'
// import VueGtag from "vue-gtag"
Vue.config.productionTip = false
// Vue.use(VueGtag, {
//   config: { id: "G-2MJN8MYZWB" }
// }, router)
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})

new Vue({
  vuetify,
  router,
	store,
  render: h => h(App)
}).$mount('#app')
