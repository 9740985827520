<template>
     <div class="d-flex align-center">
      <h2>{{title}}</h2>
      <!-- <v-btn
        depressed
        color="#4D3489"
        dark
        small
        class="ml-4"
        @click="$router.push({ name: 'Featured', params: { level: 1, type: 1 } })"
      >
        <v-icon small>mdi-plus</v-icon>
        <p class="mb-0 ml-2">Crear item</p>
      </v-btn> -->
    </div>
</template>

<script>


export default {
  props: ['title']
}
</script>
