<template>
	<v-dialog
		v-model="dialog"
		width="500"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				depressed
				color="#4D3489"
				dark
				small
				class="ml-4"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon small>mdi-plus</v-icon>
				<p class="mb-0 ml-2">Agregar Elemento</p>
			</v-btn>
		</template>

		<v-card>
			<v-card-title class="white--text" style="background-color:#4D3489;">
				Creación Contenido Multimedia
			</v-card-title>

			<v-card-text>
				<v-form
					ref="form"
					v-model="valid"
					lazy-validation
					class="d-flex flex-column flex-grow-1"
					style="width: 100%;"
				>
					<v-text-field
						v-model="name"
						:rules="requiredRules"
						label="Nombre"
						required
					></v-text-field>
					<v-text-field
						v-model="url"
						:rules="requiredRules"
						label="Url destino"
						required
					></v-text-field>
					<v-checkbox
						v-model="active"
						label="Activo"
					></v-checkbox>
					<v-file-input
						v-model="image"
						accept="image/png"
						:rules="requiredRules"
						label="Imagen"
						persistent-hint
						hint="La imagen debe ser un icono de color negro y fondo transparente (PNG)"
					></v-file-input>
				</v-form>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="#4D3489"
					outlined
					small
					@click="closeModal"
				>
					Cancelar
				</v-btn>
				<v-btn
					color="#4D3489"
					dark
					small
					@click="createElement"
				>
					Crear Contenido Multimedia
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'CreateMultimedia',
	data: () => ({
		valid: false,
		dialog: false,
		requiredRules: [
			v => !!v || 'Este campo es obligatorio'
		],
		name: '',
		url: '',
		active: true,
		image: null
	}),
	methods: {
		closeModal(){
			this.dialog = false
			this.name = ''
			this.url = ''
			this.active = true
			this.image = null
		},
		async createElement(){
			if(this.$refs.form.validate()){
				const data = {
					name: this.name,
					url: this.url,
					active: this.active ? 1:0,
					image: this.image
				}
				await this.$store.multimediaStore.dispatch("createMultimediaElement", data)
				this.closeModal()
			}
		}
	}
}
</script>